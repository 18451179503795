import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  padding: 60px 10px;
  width: 100%;
`;

export const Title = styled.h2`
  color: var(--white);
  font-family: Inconsolata;
  font-size: 40px;
  font-weight: 800;
  line-height: 41px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
`;

export const Subtitle = styled.p`
    color: var(--white);
    font-family: Inconsolata;
    font-size: 24px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-inline: auto;
  width: 90%;

  img {
    filter: invert(100%) brightness(200%);
    margin-inline: auto;
  }

  @media (max-width: 1100px) {
    .image1, .image2, .image3, .image4, .image5 {
      width: 150px;
    }
    .image5 {
      height: 70px;
    }
  }

  @media (max-width: 900px) {
    .image1, .image2, .image3, .image4, .image5 {
      height: 40px;
      width: 120px;
    }
    .image5 {
      height: 50px;
    }
  }

  @media (max-width: 700px) {
    .image1, .image2, .image3, .image4, .image5 {
      height: 30px;
      width: 90px;
    }
    .image5 {
      height: 40px;
    }
  }

  @media (max-width: 550px) {
    flex-wrap: wrap;
    .image1, .image2, .image3, .image4, .image5 {
      height: 60px;
      width: 180px;
      margin-bottom: 20px;
    }
    .image5 {
      height: 70px;
    }
  }
`;
