import styled from "styled-components";
import Button from "../../components/Button/Button";

export const Container = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 100%;
  padding: 50px 100px;

  @media (max-width: 1014px) {
    padding: 50px 80px;
  }

  /* @media (max-width: 850px) {
    width: calc(100% - 155px);
  } */

  @media (max-width: 465px) {
    padding: 40px 10px;
  }
  
  /* @media (max-width: 425px) {
    width: calc(100% - 135px);
  } */
`;

export const Main = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  align-self: center;
  /* padding: 2rem 2rem; */
  margin-top: 2rem;

  .solicitation {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 1rem;

    & > button {
      width: 250px;
      margin: 0 10px 0 0;
      border: 2px solid var(--graycard);
      border-radius: 5px;

      background: var(--white);
      padding: 0.5rem;
      cursor: pointer;

      & > h2 {
        color: var(--graycard);
        font-family: Inconsolata;
      }
    }
    
    & > .active {
      background: var(--brightpurple);
      border: 2px solid var(--brightpurple);

      & > h2 {
        color: var(--white);
      }
    }
  }

  .solicitationsType {
    /* background-color: red; */
    width: 100%;

    display: flex;
    justify-content: center;

    & > button {
      margin: 0;
      border: 0.5px solid var(--gray);
      background: var(--purple);
      padding: 1rem;
      cursor: pointer;

      & > span {
        color: var(--white);
      }
    }

    & > .active {
      background: var(--brightpurple);
    }
  }
`;

export const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  padding: 4px;
  width: 100%;

  & > img {
    border: 1px solid var(--black);
    border-radius: 50%;
    margin-right: 10px;
  }

  & > h3 {
    color: var(--brightpurple);
    margin-right: 8px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  button {
    /* border: none; */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 30px;

    & > img {
      margin-right: 10px;
    }
  }

  .secondary {
    background: var(--lightpurple);
    color: var(--black);
  }

  .primary {
    background: var(--brightpurple);
    color: var(--white);
  }

  .danger {
    background: var(--red);
    color: var(--white);
  }

  @media (max-width: 750px) {
    flex-wrap: wrap;

    .imageProfile, .buttonsCreator {
      width: 100% !important;
    }

    .buttonsCreator {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 8px;
      padding: 4px;

      button {
        margin-left: 0;
        width: 49%;
      }
    }
  }

  @media (max-width: 710px) {
    &.actionSolicitation {
      justify-content: center;
    }
  }

  @media (max-width: 550px) {
    &.actionsSolicitation {
      margin-top: 10px !important;
    }
    .buttonsCreator, &.actionsSolicitation {
      flex-direction: column;
      button {
        width: 100% !important;
      }
    }
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  border: 1px solid var(--graycard);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  margin-top: 20px;
  width: 80%;
  padding: 8px 10px;
  
  & > h2 {
    text-align: center;
  }

  & span {
    font-weight: 600;
  }

  & > p {
    margin-bottom: 8px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ContentModal = styled.div`
  border: 1px solid #b4b3b354;
  border-radius: 8px;
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;

  & > h2 {
    color: var(--black);
    font-family: Inconsolata;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  & > h3 {
    color: var(--black);
    font-family: Inconsolata;
    font-weight: 400;
  }

  .cidade {
    color: var(--yellow);
    font-family: Inconsolata;
    font-size: 16px;
    font-weight: 700;
    margin-top: 4px;
    margin-bottom: 6px;
  }

  @media (max-width: 425px) {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
`

export const HR = styled.hr`
  background: #b4b3b354;
  height: 1px;
  width: 100%;
`

export const Solicitations = styled.div`
  margin: 3rem 0;
  width: 100%;

  .influencerSolicitation {
    background-color: var(--white);
    border: 1px solid var(--graycard);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 14px;
    margin-bottom: 10px;

    div:nth-of-type(1) {
      display: flex;
    }

    img {
      border: 1px solid var(--black);
      border-radius: 50%;
      margin-right: 10px;
      height: 70px;
      width: 70px;
      object-fit: cover;
    }

    div {
      /* text-align: center; */
      .solicitationStatus {
        border-radius: 5px;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 10px;
        margin-right: 6px;
        padding: 12px;
      }

      .aceita {
        background-color: var(--lightgreen);
        border: 3px solid var(--lightgreen);
      }

      .recusado {
        background-color: var(--red);
        border: 3px solid var(--red);
        color: var(--white);
      }
      
      .newSolicitation {
        background-color: var(--lightyellow);
        border: 3px solid var(--lightyellow);
      }
      
      span {
        color: var(--mediumgray);
        font-size: 14px;
      }
      .column {
        display: flex;
        flex-direction: column;
      }
      .row {
        display: flex;
        flex-direction: row;
      }
      .creator {
        color: var(--black);
        font-family: Archivo;
        font-size: 20px;
        font-weight: 800;
      }
      .cidade {
        color: var(--yellow);
        font-family: Inconsolata;
        font-size: 16px;
        font-weight: 700;
        margin-top: 4px;
        margin-bottom: 6px;
      }
      p {
        margin-top: 10px;
      }
    }
    button {
      /* border: none; */
      border-radius: 10px;
      padding: 10px 14px;
      font-weight: 600;
      /* cursor: no-drop; */
      :hover {
        /* border: 2px solid var(--black); */
      }
    }

    button:disabled {
      border: 1px solid var(--mediumgray);
    }

    .emptySpace {
      width: 60px;
    }

    .primary {
      background: var(--brightpurple);
      color: var(--white);
      cursor: pointer;
    }

    @media (max-width: 800px) {
      flex-wrap: wrap;
      /* justify-content: center; */

      .solicitation > p {
        margin-inline: auto;
      }
    }

    @media (max-width: 675px) {
      width: 100%;
    }

    @media (max-width: 710px) {
      .details{
        justify-content: center;
        width: 100%;
      }
    }

    @media (max-width: 582px) {

      & > :nth-child(1n) {
        margin-bottom: 10px;
      }

      & > div:nth-child(2n) {
        min-width: 140px;
      }
    }
  }
`;

export const Loading = styled.div`
  border: 4px solid var(--mediumgray);
  border-top: 4px solid var(--brightpurple);
  border-radius: 50%;
  margin-left: 10px;
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  height: 30px;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const DivButton = styled(Button)`
  margin-left: auto;
  width: 190px;
  text-align: center;

  @media (max-width: 550px) {
    width: 100%;
  }
`;