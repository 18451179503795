import React, { useCallback, useEffect, useState } from "react";
import { DivRow, Card, DivColumn, Content } from "./styles";
import { Container, Loading } from "../Solicitations/styles";
import {
  CampanhaClient,
  DeleteCampaign,
  EditCampaign,
} from "../../services/cliente/client";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import Modal from "../../components/Modal/Modal";
import { toast } from "react-toastify";
import ImgUser from "../../assets/user.webp";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import InputSearch from "../../components/InputSearch";
import { formatDate } from "../../utils/helpers";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner";

const MyCampaign = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const [campanhas, setCampanhas] = useState([]);
  const [loadingCampanhas, setLoadingCampanha] = useState(false);
  const [loadingDeleteCampanha, setLoadingDeleteCampanha] = useState(false);
  const [loadingEditCampanha, setLoadingEditCampanha] = useState(false);
  const [titleModalCampanha, setTitleModalCampanha] = useState("");
  const [modalVisibleDeleteCampanha, setModalVisibleDeleteCampanha] =
    useState(false);
  const [modalVisibleEditCampanha, setModalVisibleEditPachage] = useState(false);
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome: "",
    valor: 0,
    descricao: "",
  });
  

  useEffect(() => {
    if (clientId) loadCampanhas();
  }, []);

  const loadCampanhas = async (search) => {
    try {
      setLoadingCampanha(true);

      const result = await CampanhaClient(clientId, auth, search);

      if (result) {
        setCampanhas(result.results);
        setLoadingCampanha(false);
      }
    } catch (err) {
      setLoadingCampanha(false);
      console.error("Erro: ", err);
    }
  }

  const activeModalCampanha = (title, campanha) => {
    if (campanha) {
      setPayload({
        ...payload,
        id: campanha.id,
        nome: campanha.nome_campanha,
        valor: campanha.valor,
        descricao: campanha.descricao,
        data_inicio: campanha.data_inicio ? campanha.data_inicio.slice(0, 10) : '',
        quantidade_vagas: campanha.quantidade_vagas,
        data_termino: campanha.data_termino ? campanha.data_termino.slice(0,10) : ''
      });
    } else {
      const newPayload = {
        cliente: clientId,
        nome: "",
        valor: 0,
        data_inicio: "",
        quantidade_vagas: 0,
        data_termino: ""
      };
      setPayload(newPayload);
    }
    setTitleModalCampanha(title);
    if (title === "Apagar Campanha") {
      setModalVisibleDeleteCampanha(true);
    } else {
      setModalVisibleEditPachage(true);
    }
  };

  const submitCampanha = async () => {
    if (validate()) {
      setLoadingEditCampanha(true);
      try {
        if (payload.id) {
          let _payload = {
            nome: payload.nome,
            valor: payload.valor,
            descricao: payload.descricao,
          };

          const result = await EditCampaign(payload.id, _payload, auth);
          if (result) {
            toast.success("Campanha editada com sucesso!");
            setLoadingEditCampanha(false);
            setModalVisibleEditPachage(false);
            let _package = campanhas.map((pacote) => {
              if (pacote.id === result.id) {
                return {
                  ...pacote,
                  nome: result.nome,
                  valor: result.valor,
                  descricao: result.descricao,
                };
              } else {
                return pacote;
              }
            });
            setCampanhas(_package);
          }
        }
      } catch (error) {
        setLoadingEditCampanha(false);
        setModalVisibleEditPachage(false);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!payload.nome) {
      toast.error("Digite o nome do pacote");
      return false;
    }
    if (!payload.valor) {
      toast.error("Digite o valor do pacote");
      return false;
    }
    if (!payload.descricao) {
      toast.error("Digite a descrição do pacote");
      return false;
    }
    return true;
  };

  const deleteCampanha = async () => {
    if (payload.id) {
      try {
        setLoadingDeleteCampanha(true);
        const result = await DeleteCampaign(payload.id, auth);
        if (result) {
          let _package = campanhas.filter((pacote) => pacote.id !== payload.id);

          setCampanhas(_package);
          toast.success("Campanha excluída");
          setLoadingDeleteCampanha(false);
          setModalVisibleDeleteCampanha(false);
        }
      } catch (error) {
        setLoadingDeleteCampanha(false);
        console.error("Erro: ", error);
      }
    }
  };

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  return (
    <Content style={{ justifyContent: "center" }}>
      <DivColumn>
        <DivBetween style={{marginBottom: 14}}>
          <Title>Minhas Campanhas</Title>
          {/* <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage> */}
        </DivBetween>
        <InputSearch
          bgColor={"var(--white)"}
          name="search"
          placeholder="Pesquisar nome da campanha"
          submit={loadCampanhas}
        />
        <DivRow style={{paddingBottom: 20}}>
          {campanhas.length ? campanhas.map((campanha) => (
            <Card key={campanha.id}>
              <h2>{campanha.nome_campanha}</h2>
              <p>Publicada: {campanha.criado_em ? formatDate(campanha.criado_em) : ''}</p>
              <DivRow style={{marginBottom: 6}}>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Valor:</span> R$ {campanha.valor}
                </p>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Início:</span> {campanha.data_inicio ? formatDate(campanha.data_inicio) : ''}
                </p>
              </DivRow>
              <DivRow>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Total de vagas:</span> {campanha.quantidade_vagas}
                </p>
                <p style={{marginBottom: 6, width: '50%'}}>
                  <span>Encerramento:</span> {campanha.data_termino ? formatDate(campanha.data_termino) : ''}
                </p>
              </DivRow>
              <DivRow className="justify-end" style={{alignItems: 'center', marginBottom: 8, padding: 8}}>
                <Button
                  setBackgroundColor={'var(--brightpurple)'}
                  setColor={'var(--white)'}
                  setShadow={'var(--brightpurple)'}
                  style={{marginBottom: 0}}
                  onClick={() => activeModalCampanha("Editar Campanha", campanha)}
                >
                  Editar
                </Button>
                <Button
                  setBackgroundColor={'var(--red)'}
                  setColor={'var(--white)'}
                  setShadow={'var(--red)'}
                  style={{marginBottom: 0}}
                  onClick={() => activeModalCampanha("Apagar Campanha", campanha)}
                >
                  Excluir
                </Button>
              </DivRow>
            </Card>
          )) : null}
        </DivRow>
        {loadingCampanhas ? <Spinner /> : <div style={{ height: 80 }}></div>}
      </DivColumn>

      {/* Editar Campanha */}
      {modalVisibleEditCampanha ? (
        <Modal
          title={titleModalCampanha}
          setShouldOpenModal={() => setModalVisibleEditPachage(false)}
        >
          <div>
            <div>
              <Container style={{ flexDirection: "column", marginTop: 0, minHeight: 'unset' }}>
                <label htmlFor="nome">Nome da oferta</label>
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  value={payload.nome ? payload.nome : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) => handlePayload("nome", target.value)}
                />
                <label htmlFor="valorServico">Valor</label>
                <input
                  type="number"
                  name="valorServico"
                  id="valorServico"
                  value={payload.valor ? payload.valor : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("valor", target.value)
                  }
                />
                <label htmlFor="descricao">Descrição detalhada</label>
                <textarea 
                  id="descricao"
                  value={payload.descricao ? payload.descricao : ""}
                  style={{ width: "100%", height: "auto" }}
                  rows={10}
                  onChange={({ target }) =>
                    handlePayload("descricao", target.value)
                  }
                />
              </Container>
              {/* <DivRow style={{justifyContent: 'space-between'}}>
                <Container style={{ flexDirection: "column", width: '48%'}}>
                  <label htmlFor="valorServico">Valor</label>
                  <input
                    type="number"
                    name="valorServico"
                    id="valorServico"
                    value={payload.valor ? payload.valor : ""}
                    style={{ width: "100%" }}
                    onChange={({ target }) =>
                      handlePayload("valor", target.value)
                    }
                  />
                </Container>
                <Container style={{ flexDirection: "column", width: '48%' }}>
                  <label htmlFor="data_inicio">Início</label>
                  <input
                    type="date"
                    name="data_inicio"
                    id="data_inicio"
                    value={payload.data_inicio ? payload.data_inicio : ""}
                    style={{ width: "100%" }}
                    onChange={({ target }) =>
                      handlePayload("data_inicio", target.value)
                    }
                  />
                </Container>
              </DivRow>
              <DivRow style={{justifyContent: 'space-between'}}>
                <Container style={{ flexDirection: "column", width: '48%'}}>
                  <label htmlFor="quantidade_vagas">Total de Vagas</label>
                  <input
                    type="number"
                    name="quantidade_vagas"
                    id="quantidade_vagas"
                    min={0}
                    value={payload?.quantidade_vagas || ""}
                    style={{ width: "100%" }}
                    onChange={({ target }) =>
                      handlePayload("quantidade_vagas", target.value)
                    }
                  />
                </Container>
                <Container style={{ flexDirection: "column", width: '48%' }}>
                  <label htmlFor="data_termino">Término</label>
                  <input
                    type="date"
                    name="data_termino"
                    id="data_termino"
                    value={payload.data_termino ? payload.data_termino : ""}
                    style={{ width: "100%" }}
                    onChange={({ target }) =>
                      handlePayload("data_termino", target.value)
                    }
                  />
                </Container>
              </DivRow> */}
              
              <DivRow style={{ justifyContent: "flex-end" }}>
                {loadingEditCampanha ? <Loading style={{ margin: 10 }} /> : null}
                <Button
                  setBackgroundColor={'var(--white)'}
                  type="button"
                  disabled={loadingEditCampanha}
                  onClick={() => submitCampanha()}
                  style={{border: '1px solid var(--black)'}}
                >
                  Editar Campanha
                </Button>
              </DivRow>
            </div>
          </div>
        </Modal>
      ) : null}

      {/* Apagar Campanha  */}
      {modalVisibleDeleteCampanha ? (
        <Modal
          title={titleModalCampanha}
          setShouldOpenModal={() => setModalVisibleDeleteCampanha(false)}
        >
          <Container
            style={{
              height: 200,
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div>
              <h2>Tem certeza que deseja excluir essa campanha?</h2>
              <p
                style={{
                  color: "#000",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Esta ação é irreversível
              </p>
            </div>
            <DivRow className="justify-center">
              {loadingDeleteCampanha ? <Loading style={{ margin: 10 }} /> : null}
              <button
                className="primary"
                onClick={() => setModalVisibleDeleteCampanha(false)}
                disabled={loadingDeleteCampanha}
              >
                Cancelar
              </button>
              <button
                className="danger"
                onClick={() => deleteCampanha()}
                disabled={loadingDeleteCampanha}
              >
                Apagar
              </button>
            </DivRow>
          </Container>
        </Modal>
      ) : null}
    </Content>
  );
};
export default MyCampaign;
