import styled from "styled-components";

export const Card = styled.div`
    border: 3px solid var(--black-50);
    box-shadow: 5px 5px 0 0 #1e1e1e;
    border-radius: 15px;
    min-height: 100px;
    height: auto;
    margin-bottom: 10px;
    padding: 27px 35px;
    transition: all 0.8s linear;
`;

export const DivRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.h3`
    color: var(--black-50);
    font-family: Inconsolata;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    margin-bottom: 8px;
    width: 85%;
`;

export const Response = styled.p`
    color: var(--black-50);
    font-family: Inconsolata;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
`;