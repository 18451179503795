import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media (max-width: 1024px) {
    margin: 4rem 0;
  }
`;

export const LeftSide = styled.div`
  width: 40vw;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const RightSide = styled.div`
  /* background-color: fuchsia; */
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--softgray);
  border-radius: 16px;
  box-shadow: 5px 5px 12px 2px #646464;
  padding: 4rem;
  height: 550px;
  justify-content: space-between;

  h2 {
    color: var(--purple);
    text-shadow: 2px 2px 4px rgba(60, 46, 84, 0.34);
  }

  p {
    font-size: 14px;
    color: var(--mediumgray);
    margin: 10px 0px;
  }

  @media (max-width: 1024px) {
    padding: 2rem;
    justify-content: space-aroundç;
    p {
      text-align: center;
    }
  }
`;
