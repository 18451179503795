import api from "../index";

export async function updateProflePicture(clientId, payload) {
  const userLoged = localStorage.getItem("@boraver");

  
  if (!userLoged) {
    return;
  }

  const userInfo = JSON.parse(userLoged);
  const userId = userInfo.user.id;
  const token = userInfo.token
  const response = await api.patch(`/api/v1/clientes/${clientId}/`,
  {
    profile_picture: payload
  }, 
  {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  return response.data;
}