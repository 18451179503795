import styled from 'styled-components'

export const Container = styled.button`
  cursor: pointer;
  /* border: 2px solid ${props => props.setBackgroundColor}; */
  border: 2px solid var(--black-50);
  border-radius: 5px;
  background-color: ${props => props.setBackgroundColor};
  color: ${props => props.setColor};
  padding: ${(props) => (props.large ? "8px 48px" : "8px 12px")};
  font-size: ${(props) => (props.large ? "20px" : "16px")};
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  
  :hover{
    /* background-color: #dfa601; */
    opacity: 0.8;
    box-shadow: ${props => props.setShadow ? `0 0 8px 1px ${props.setShadow}` : "0 0 8px 1px #dfa601"};
  }

  :disabled {
    cursor: no-drop;
    background-color: var(--gray);
    box-shadow: none;
  }

  @media (max-width: 400px) {
    font-size: ${(props) => (props.large ? "18px" : "14px")};
    padding: ${(props) => (props.large ? "8px 48px" : "8px 6px")};
  }
`