import React from "react";
import { Container, DivRow, Subtitle, Title } from "./styles";
import ImageHero from "../../assets/fotos-hero.png";
import Button from "../Button/Button";
import Star from "../../assets/star.png";
import { useHistory } from "react-router-dom";

const SectionHero = () => {
  const history = useHistory();

  const handleNavigation = (path) => {
    return history.push(path);
  };

  return (
    <Container>
      <DivRow>
        <img className="imageHero" src={ImageHero} />
        <div>
          <Title>
            Conecte sua marca com mais de 9.000 criadores de conteúdo
          </Title>
          <Subtitle>
            Comece agora a enviar e receber propostas de conteúdos UGC personalizados.
          </Subtitle>
          <DivRow>
            <div style={{ flexDirection: "row", width: "80%" }}>
              <Button
                setBackgroundColor="var(--yellow)"
                setColor="var(--black-50)"
                onClick={() => handleNavigation("/signup")}
              >
                Criar uma conta
              </Button>
              <Button
                setBackgroundColor="var(--white)"
                setColor="var(--black-50)"
              >
                <a
                  href="https://wa.me/5598984329987?text=Ol%C3%A1!%20Pode%20me%20explicar%20como%20funciona%20a%20plataforma%20Boraver%20UGC%3F"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none', color: 'var(--black)'}}
                >
                  Agendar Apresentação
                </a>
              </Button>
            </div>
            <img src={Star} style={{ width: 120 }} />
          </DivRow>
        </div>
      </DivRow>
    </Container>
  );
};

export default SectionHero;
