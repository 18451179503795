import { Card, DivRow, Response, Title } from "./styles";
import { TbCirclePlus } from "react-icons/tb";
import { TiMinus } from "react-icons/ti";


const CardFaq = ({ id, title, response, status, alterStatus }) => {
    return (
        <Card>
            <DivRow>
                <Title>{title}</Title>
                {status ? (
                    <TiMinus size={45} onClick={() => alterStatus(id)} style={{ cursor: "pointer" }} />
                ) : (
                    <TbCirclePlus size={45} onClick={() => alterStatus(id)} style={{ cursor: "pointer" }} />
                )}
            </DivRow>
            {status ? (
                <Response>{response}</Response>
            ) : null}
        </Card>
    )
}
export default CardFaq;