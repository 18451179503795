import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 3rem 0;
  max-width: 100%;
  min-height: 80vh;
  @media (max-width: 1023px) and (min-width: 769px) {
    padding: 4rem 0;
  }
`;

export const RightSide = styled.div`
  max-width: 50%;
  display: flex;
  align-items: center;
  img {
    width: 90%;
  }
  @media (max-width: 1024px) {
    max-width: 40%;
  }
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LeftSide = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;

  .pageOne > div {
    display: flex;
    flex-direction: column;
    color: var(--purple);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-bottom: 5px;

  }
  
  .pageOne > .custom-select {
    min-height: max-content !important;
    overflow: visible;

    & > label {
      line-height: 12px;
      margin-top: 6px;
      margin-bottom: 0;
    }

    & > span {
      color: var(--purple);
      font-size: 12px;
      margin-top: 0;
      margin-bottom: 8px;
      text-align: left;
    }

    & > div {
      overflow: visible;
      font-size: 14px;

      
      .css-1jqq78o-placeholder{
        color: var(--black);
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }

      .css-1xc3v61-indicatorContainer {
        color: var(--black);
        padding: 8px 4px
      }

      #react-select-3-listbox{
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
        border: 1px solid #ccc;
        left: 2px;
        right: 2px;
        width: 96%;
      }
    }
  }

  .pageOne > div > select {
    border: 2px solid var(--gray);
    border-radius: 4px;
    height: 40px;
    width: 50%;
  }

  .pageOne {
    display: ${(props) => (props.page === 1 ? "block" : "none")};
  }

  .pageTwo {
    display: ${(props) => (props.page === 2 ? "block" : "none")};
  }

  .pageThree {
    display: ${(props) => (props.page === 3 ? "block" : "none")};
  }

  .pageFour {
    display: ${(props) => (props.page === 4 ? "block" : "none")};
  }

  .pageableButtons {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }

  .image-container {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    overflow: hidden;
    position: relative;

    &:hover {
      .logo {
        display: block;
      }
    }

    & > img {
      width: 50px;
      height: 50px;
      border-radius: 25px; 
    }

    .logo {
      color: white;
      width: 50px;
      height: 50px;
      display: none;
      position: absolute;
      right: 0;
      bottom: 0px;
      background: var(--purple);
      padding: 10px;
      border-radius: 50%;
      opacity: .6;
    }

    .spinner-container{
      right: 0 !important;
      bottom: 0 !important;
      background: #3c2e5496;
      border-radius: 25px;
      height: 50px;
      width: 50px;
    }
  }

  @media (max-width: 1024px) {
    width: 60vw;
  }
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px 0;
  width: 80%;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  box-shadow: 5px 5px 12px 2px #646464;
  h2 {
    text-align: center;
    color: var(--purple);
    padding-bottom: .6rem;
    text-shadow: 2px 2px 4px rgba(100, 100, 100, 0.5);
  }

  .btn-place {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  span {
    text-align: center;
    color: #646464;
    margin-top: 10px;
    font-size: 14px;
    a {
      font-weight: 600;
      cursor: pointer;
      color: var(--purple);
      :hover {
        color: var(--brightpurple);
      }
    }
  }
`;

export const TipoRadio = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;

  label {
    width: fit-content;
    cursor: pointer;
  }

  input {
    margin-right: 6px;
  }

  @media (max-width: 425px) {
    flex-direction: column !important;

    label {
      margin-bottom: 3px;
    }
  }
`;

export const LabelTipo = styled.p`
  margin-bottom: 5px;
  color: var(--purple);
  font-size: 15px;
  font-weight: 500;
`;

export const SelectCustom = styled.select`
  margin-top: 4px;
  margin-bottom: 8px;
  width: 100%;
  border-radius: 4px;
  height: 36px;
  border: 2px solid var(--gray);
`;

export const MensagemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px 0;
  width: 80%;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  box-shadow: 5px 5px 12px 2px #646464;

  h2 {
    color: var(--purple);
    margin-bottom: 22px;
  }

  p {
    margin-bottom: 12px;
  }

  a {
    color: var(--purple);
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div {
    border-radius: 4px;
    border: 2px solid var(--gray); 
    margin-bottom: 5px;
    color: var(--purple);
    font-size: 14px;
    padding: 10px;
    width: 100%;
    transition: 0.4s;

    & > input {
      background: var(--white);
      border: 0;
      color: var(--black);
      width: 100%;

      &::placeholder {
        color: var(--gray);
      }
    }
  }
`;