import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    color: var(--purple);
    border-bottom: 2px solid var(--gray);
    padding-bottom: 10px;
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const TopContent = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0rem 1rem 0rem;

  .notificationProfilePic {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border: 1px solid var(--mediumgray);
      border-radius: 50%;
      margin-bottom: 1rem;
      height: 140px;
      width: 140px;
      object-fit: cover;
    }
  }
  .notificationProfileName {
    text-align: center;
    min-width: 180px;
  }
  button {
    border: none;
    padding: 12px 30px;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    min-width: 250px;
  }

  @media (max-width: 840px) {
    width: 100%;
  }

  @media (max-width: 680px) {
    flex-wrap: wrap;

    & > div {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 430px) {
    justify-content: center;
  }
`;

export const Proposition = styled.div`
  max-width: 100%;
  padding: 2rem 0rem 0rem 0rem;

  @media (max-width: 840px) {
    max-width: 100%;
  }
`;

export const PropositionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  div {
    /* min-width: 220px; */
    width: 30%;
    text-align: center;
    color: var(--purple);
    margin-bottom: 30px;
    p {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .propositionStatus {
    /* margin-top: 2rem; */
  }
  .propostitionStatusResponse {
    font-weight: 600;
  }

  @media (max-width: 488px) {
    & > div {
      margin-bottom: 10px;
    }

    & > .propositionStatus {
      margin-top: 1rem;
    }
  }
`;

export const Messages = styled.div`
  padding: 0 0 2rem 0;
  height: 70vh;
  overflow-y: auto;
  scroll-behavior: smooth;

  h2 {
    background: var(--white);
    padding-top: 20px;
    position: sticky;
    top: 0;
  }

  .chat {
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
    /* scroll-behavior: smooth; */
  }

  .chat > .notMessage {
    text-align: center;
    width: 80%;
  }
`;

export const NewMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background-color: blue; */
  .newMessageContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    width: 100%;
    /* background-color: fuchsia; */
    .input-group {
      border: 1px solid var(--gray);
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 80%;
    }

    .newMessageInput {
      border: none;
      padding: 20px;
      font-size: 14px;
      width: 94%;
      ::placeholder {
        font-family: "Noto Serif HK", serif;
      }
    }

    button:not(.send) {
      border: none;
      background: var(--white);
      cursor: pointer;
    }
    svg {
      color: var(--purple);
      font-size: 24px;
    }

    .send {
      width: 15%;
      min-width: 100px;
      cursor: pointer;
      height: 60%;
      border: none;
      border-radius: 10px;
      font-weight: 500;
      background-color: var(--purple);
      color: var(--white);
      font-size: 16px;
      margin-left: 10px;
      :hover {
        background-color: var(--green);
      }
    }
  }

  @media (max-width: 390px) {
    .newMessageInput {
      width: 90% !important;
    }
  }
`;
export const FilesAttached = styled.div`
  .filesDiv {
    display: flex;
    max-width: 80%;
    min-width: 570px;
    justify-content: center;
    margin-bottom: 50px;
    div {
      background-color: var(--yellow);
      border: 1px solid var(--mediumgray);
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 180px;
      margin: 10px;
      min-width: 170px;
      width: 170px;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        padding: 86px 80px;
      }
    }

    @media (max-width: 625px) {
      flex-wrap: wrap;
      min-width: inherit;
      max-width: 100%;
    }
  }
`;

// ### input checkout ###

export const Input = styled.input`
  margin: 9px 0;
  height: 34px;
  border: 2px solid #b4b4b4;
  border-radius: 3px;
  padding: 0px 8px;
`;

export const MaskInput = styled(ReactInputMask)`
  margin: 9px 0;
  height: 34px;
  border: 2px solid #b4b4b4;
  border-radius: 3px;
  padding: 0px 8px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
  margin-top: 9px;

  input {
    margin: 0;
  }
  select {
    margin: 0;
  }
`;

export const Select = styled.select`
  border: 2px solid #b4b4b4;
  border-radius: 4px;
  height: 34px;
  margin-top: 18px;
`;

export const Button = styled.button`
  background-color: ${(props) => (props.isDisabled ? "gray" : "var(--purple)")};
  margin-top: 18px;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  font-size: 20px;
  cursor: ${(props) => (props.isDisabled ? "unset" : "pointer")};
`;

export const HeaderPayment = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0px 12px;
  margin-bottom: 9px;
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-right: 6px;
  }
  .icon {
    margin-right: 6px;
  }
  .img {
    width: 18px;
    margin-right: 3px;
  }
`;

export const PixContainer = styled.div`
  display: contents;
  p {
    margin: 8px 0px;
  }
`;

export const QRCodeContainer = styled.div`
  text-align: center;
  margin-top: 9px;
`;

export const ConfirmPixContainer = styled.div`
  text-align: center;
`;

export const SectionModal = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-bottom: 20px;
  }

  input {
    display: none;
  }
  label {
    color: var(--yellow);
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  button {
    background-color: var(--red);
    border: none;
    border-radius: 10px;
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 8px;
    padding: 10px;
  }

  button:disabled {
    background-color: var(--mediumgray);
    cursor: no-drop;
  }

  .loader {
    border: 4px solid var(--mediumgray);
    border-top: 4px solid var(--brightpurple);
    border-radius: 50%;
    min-width: 30px;
    max-width: 30px;
    width: 30px;
    min-height: 30px;
    max-height: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;