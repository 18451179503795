import api from "./index"

export const Remuneracoes = async (token) => {
    try {
        const response = await api.get("/api/v1/remuneracao/", {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}