import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    padding: 50px 10px;
    width: 90%;

    @media(max-width: 715px) {
        flex-direction: column;
    }
`;

export const DivColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.wide};

    h2 {
        color: var(--black);
        font-family: Inconsolata;
        font-size: 40px;
        font-weight: 800;
        line-height: 41px;
        margin-bottom: 20px;
    }

    span {
        color: var(--brightpurple);
        text-decoration: underline;
    }

    .paragraph {
        color: var(--black);
        font-family: Inconsolata;
        font-size: 20px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 20px;
    }

    button {
        padding: 18px 12px;
        width: 250px;
    }

    button:hover {
        background-color: #dfa601;
        box-shadow: 1px 3px 15px 1px #dfa601;
    }

    @media(max-width: 715px) {
        margin-bottom: 20px;
        width: 100%;
    }
`;