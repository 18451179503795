import { useEffect, useState } from "react";
import LogoPic from "../../assets/Logotipo_boraver.png";
import { useHistory, Link } from "react-router-dom";
import Button from "../Button/Button";
import { BsBellFill } from "react-icons/bs";
import { Container, Content, DivImage, DropdownItem, DropdownMenu, MobileMenu, RightSideWrapper } from "./styles";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import { FaTimes } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import Modal from "../Modal/Modal";
import ImgUser from "../../assets/user.webp";
import { IoMdClose } from "react-icons/io";

const Header = ({ isAuth, visibleSidebar, toggleSidebar }) => {
  const { setAuth, auth } = useAuth();

  const { unreadNotifications, setUserId, userData } = useClient();

  const history = useHistory();
  var url = window.location.href;

  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [linkConvite, setLinkConvite] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const partesDaURL = url.split("/");

    if (
      partesDaURL.includes("influencerDetails") |
        partesDaURL.includes("notifications") &&
      !auth
    ) {
      setLinkConvite(true);
    }
  }, []);

  const handleNavigation = (path) => {
    return history.push(path);
  };

  const handleLogout = () => {
    setAuth();
    setUserId();
    localStorage.clear();
    return history.push("/");
  };

  const handleLogoClick = () => {
    if (isAuth) {
      return history.push("/dashboard");
    }
    if (!isAuth) {
      return history.push("/");
    }
  };

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleEditProfile = () => {
    history.push('/clientprofile');
  };

  const renderRightSide = () => {
    if (isAuth) {
      return (
        <div 
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage>
          {/* Dropdown Menu */}
          <DropdownMenu show={isDropdownOpen}>
            <DropdownItem onClick={()=> handleEditProfile()}>
              Editar Perfil
            </DropdownItem>
          </DropdownMenu>
        </div>
      );
    }
    if (!isAuth && !linkConvite) {
      return (
        <div className="right-side">
          <Button
            setBackgroundColor="transparent"
            setColor="var(--black)"
            onClick={() => handleNavigation("/login")}
            style={{ border: "none" }}
          >
            Login
          </Button>
          <Button
            setBackgroundColor="var(--yellow)"
            setColor="var(--black)"
            onClick={() => handleNavigation("/signup")}
          >
            Cadastre-se
          </Button>
          <div className="hamburger" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <GiHamburgerMenu size={26} fill="var(--black)" />
          </div>
          <MobileMenu isMenuOpen={isMenuOpen}>
            <div className="close-button" onClick={() => setIsMenuOpen(false)}>
              <FaTimes size={26} fill="var(--black)" />
            </div>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>
              Home
            </Link>
            <Link to="lista-creators" onClick={() => setIsMenuOpen(false)}>
              Encontrar Creators
            </Link>
            <Link to="links-creator">Virar um Creator</Link>
            {/* <a
              href="https://linktr.ee/boraverapp"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setIsMenuOpen(false)}
            >
              Virar um Creator
            </a> */}
          </MobileMenu>
        </div>
      );
    }
  };

  return (
    <Container isAuth={isAuth} visible={visibleSidebar}>
      <Content>
        <div className="left-side">
          {!!isAuth && (
            <div>
              {!visibleSidebar ? (
                <GiHamburgerMenu fill="#000" onClick={() => toggleSidebar()} />
              ) : (
                <IoMdClose fill="#000" onClick={() => toggleSidebar()} />
              )}
            </div>
          )}
          {!isAuth ? (
            <div className="logoDiv">
              <img
                src={LogoPic}
                alt="logo-boraver"
                onClick={() => handleLogoClick()}
              />
            </div>
          ) : null}
        </div>
        {!isAuth ? (
          <div className="links">
            <Link to="/">Home</Link>
            <Link to="lista-creators">Encontrar Creators</Link>
            <Link to="links-creator">Virar um Creator</Link>
            {/* <a
              href="https://linktr.ee/boraverapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              Virar um Creator
            </a> */}
          </div>
        ) : null}
        {renderRightSide()}
      </Content>
    </Container>
  );
};
export default Header;
