import styled from "styled-components";

export const Content = styled.div`
  background-color: var(--white-50);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  min-height: 90dvh;
  width: 82%;
  padding: 50px 30px;

  @media (max-width: 850px) {
    width: calc(100% - 155px);
  }

  @media (max-width: 465px) {
    padding: 40px 20px;
  }
  
  @media (max-width: 425px) {
    width: calc(100% - 135px);
  }
`; 

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0 30px;
  width: 100%;

  & > img {
    margin-right: 10px;
  }

  & > h3 {
    color: var(--brightpurple);
    margin-right: 8px;
  }

  &.justify-between {
    justify-content: space-between;
  }

  .justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  button {
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 14px 30px;

    & > img {
      margin-right: 10px;
    }
  }

  .secondary {
    background: var(--lightpurple);
    color: var(--black);
  }

  .primary {
    background: var(--brightpurple);
    color: var(--white);
  }

  .danger {
    background: var(--red);
    color: var(--white);
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  margin-inline: 8px;
  min-height: 200px;
  width: 48%;
  padding: 12px;

  & h2 {
    font-family: Inconsolata;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & p {
    font-family: Inconsolata;
    font-weight: 400;
    margin-bottom: 20px;
  }

  & span {
    font-weight: 600;
  }

  & > p {
    margin-bottom: 8px;
  }
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  & > h2 {
    color: var(--brightpurple);
    font-size: 26px;
    margin: 20px 20px 20px 4%;
  }

  @media (max-width: 465px) {
    width: 95% !important;
  }
`;
