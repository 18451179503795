import styled from "styled-components";

export const Container = styled.div`
  ${props => props.logged ? "background-color: var(--white-50);" : null}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${props => props.logged ? "margin-left: auto;" : null}
  min-height: 75vh;

  /* @media (max-width: 700px) {
    ${props => props.logged ? "width: calc(100% - 155px);" : null}
  }
  
  @media (max-width: 425px) {
    ${props => props.logged ? "width: calc(100% - 135px);" : null}
  } */
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    width: 100% !important;
  }
`;

export const Content = styled.div`
  margin: 3rem 0rem 0rem;
  display: flex;
  width: 90%;
  justify-content: center;
  
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    width: 100% !important;
  }
`;

export const Highlights = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-inline: auto;
  max-width: 40%;

  .inFluencerPicture {
    /* box-shadow: 6px 6px 5px 1px rgba(0, 0, 0, 0.4);
    width: 400px; */
    img {
      border: 3px solid #2F251B;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      width: 240px;
      height: 240px;
      max-height: 20rem;
    }
  }

  .influencerName {
    /* background-color: var(--purple); */
    color: #2F251B;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 1rem 4rem;
    text-transform: uppercase;
    /* box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.4); */

    h2 {
      text-align: center;
      font-family: "Poppins", sans-serif;
    }
  }

  .cidade {
    color: #E89636;
    font-family: Inconsolata;
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
  }

  .sociais {
    display: flex;

    button {
      border: none;
      background-color: transparent;

      svg {
        font-size: 44px;
        margin-top: 20px;
      }
    }
  }

  .portifolio {

    button {
      border: 3px solid #2F251B;
      border-radius: 5px;
      font-family: Inconsolata;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-top: 20px;
      padding: 18px 12px;
      gap: 10px;
      width: 218px;

      a {
        color: #2F251B;
        text-decoration: none;
      }

      &:hover {
        background-color: #E89636;
        box-shadow: 1px 3px 15px 1px #E89636;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    max-width: 50%;
    .influencerName {
      padding: 10px 40px;
      h2 {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    max-width: 90%;
    .influencerName {
      padding: 10px 30px;
      h2 {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 425px) {
    .inFluencerPicture > img {
      height: 120px;
      width: 120px;
    }

    .sociais > button > svg {
      font-size: 32px;
    }
  }

  @media (max-width: 400px) {
    .cidade {
      font-size: 22px;
    }
  }
`;

export const Details = styled.div`
  width: 100%;
  margin: 2rem 0rem 3rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) and (max-width: 1023px) {
    /* width: 40%; */
  }
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const Informations = styled.div`
  width: 100%;

  div {
    margin-bottom: 2rem;
    h3 {
      color: #2F251B;
      /* border-bottom: 2px solid var(--gray); */
      margin-bottom: 10px;
      font-family: "Poppins", sans-serif;
    }
    p {
      font-family: "Noto Serif HK", serif;
    }
  }

  .pacotes {
    width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }

  .card {
    border: 3px solid #2F251B;
    border-radius: 10px;
    padding: 16px 12px;
    margin-right: 10px;
    box-shadow: 4px 4px 0 0 #1E1E1E;
    min-width: 300px;
    max-width: 350px;

    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }

    h3, span {
      color: #2F251B;
      font-size: 24px;
      font-weight: 800;
    }

    p {
      color: #000;
      font-family: Inconsolata;
      font-size: 22px;
      font-weight: 400;
      margin-top: 6px;
      margin-bottom: 20px;
    }

  }

  .checkoutButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    width: 100%;

    button {
      background-color: #34D399;
      border: 3px solid #2F251B;
      border-radius: 5px;
      cursor: pointer;
      font-family: Inconsolata;
      font-size: 24px;
      font-weight: 500;
      padding: 10px 12px;
    }
  }

  & + div {
    width: 70%;
    display: flex;
    justify-content: space-evenly;

    button + button {
      border: 2px solid var(--purple);
      border-radius: 4px;
      background: transparent;
      padding: 0.5rem 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      & strong {
        font-weight: 500;
        color: var(--brightpurple);
        font-size: 1rem;
      }

      & > img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .button-alert {
      background: var(--alert);
      border: 1px solid var(--alert);

      & > a {
        color: var(--white);
        text-decoration: none;
      }

      &:hover {
        background-color: var(--lightalert);
        box-shadow: 1px 3px 15px 1px #dabd0ca1;

        & > a {
          color: var(--darkgray);
        }
      }
    }

    @media(max-width: 600px) {
      width: 100%;
    }
  }

  @media (max-width: 540px) {
    width: 90%;
  }

  @media (max-width: 425px) {
    .card{
      padding: 12px;
      h3, span {
        font-size: 18px;
      }
      p {
        font-size: 16px;
      }
    }
  }
`;

export const PacotesList = styled.div`
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  margin: 0 0 16px 0;
  max-height: 200px;
  overflow-y: scroll;

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemPacote = styled.div`
  width: 100% !important;
  cursor: pointer;
  background-color: ${(props) => (props.choice ? "#3c2e54" : "#e3e3e3")};
  border-radius: 3px;
  padding: 3px 3px 3px 8px;

  p {
    font-size: 16px !important;
    font-weight: 600;
    color: ${(props) =>
      props.choice ? "white !important" : "black !important"};
    margin: 3px 0;
  }

  span {
    color: ${(props) =>
      props.choice ? "white !important" : "black !important"};
  }
`;

export const Total = styled.p`
  margin: 0px 0 22px 0;
  font-size: 16px !important;
  font-weight: 600;
  color: black !important;
`;

export const ImageFeed = styled.img`
    border-radius: 10px;
    align-self: center;
    margin-inline: 10px;
    width: 360px;
    height: 220px;

    @media (max-width: 550px) {
      width: 280px;

      &.lastItem {
        margin-bottom: 30px;
      }
    }
`;

export const ImageStorie = styled.img`
    border-radius: 10px;
    align-self: center;
    margin-inline: 15px;
    margin-bottom: 40px;
    width: 280px;
    height: 470px;
`;