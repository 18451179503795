import api from "../../services/index";

export const postSolicitation = async (payload, token) => {
  try {
    const response = await api.post(`api/v3/solicitacaocriar/`, payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : null,
      },
    });

    return response.data;
  } catch (error) {
    // console.log(error.response.data[0].details)
    throw Error(`${error.response.data[0].details}`);
  }
};


export const getSolicitation = async (id, auth) => {
  try {
    const response = await api.get(`api/v3/solicitacao/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: auth ? `Bearer ${auth}` : null,
      },
    })
    
    return response.data
  } catch (error) {
    throw Error(`${error.response.data[0].details}`);
  }
}

export const alterStatusSolicitation = async (id, status, auth) => {
  const payload = {
    status: status
  }

  if(status === 'Em Andamento') {
    payload['inicio'] = String(Date());
  }
  try {
    const response = await api.patch(`/api/v3/solicitacao/${id}/`, {...payload}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    })

    return response.data
  } catch (error) {
    throw Error(`${error.response.data[0].details}`);
  }
}

export const alterStatusCampanha = async (id, status, auth) => {
  const payload = {
    status: status
  }

  try {
    const response = await api.patch(`/api/v1/candidaturas/${id}/`, {...payload}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth}`,
      },
    })

    return response.data
  } catch (error) {
    throw Error(`${error.response.data[0].details}`);
  }
}