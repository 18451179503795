import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Input from "../../components/Input/Input";
import SignupPic from "../../assets/SignupPic.png";
import {
  Column,
  Content,
  Form,
  LabelTipo,
  LeftSide,
  MensagemContainer,
  RightSide,
  SelectCustom,
  TipoRadio,
} from "./styles";
import Button from "../../components/Button/Button";
import { useHistory } from "react-router-dom";
import api from "../../services";
import { toast } from "react-toastify";
import { useAuth } from "../../providers/auth";
import { regioes as regioesNomes, onlyNumber } from "../../utils/helpers";
import Spinner from "../../components/Spinner";
import EditIcon from "../../assets/icon.svg";
import Logo from "../../assets/user.webp";
import { uploadFile } from "../../services/cliente/upload";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import InputMask from "react-input-mask";

const animatedComponents = makeAnimated();

const Signup = () => {
  const { auth } = useAuth();
  const [masked, setMasked] = useState("999.999.999-999");
  const [tipo, setTipo] = useState("anunciante");
  const [page, setPage] = useState(1);
  const [mensagem, setMensagem] = useState(false);
  const [segments, setSegments] = useState([]);
  const [segmentSelected, setSegmentSelected] = useState("");
  const [targetAudience, setTargetAudience] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidadeSelected, setCidadeSelected] = useState("");
  const [estadoSelected, setEstadoSelected] = useState("");
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [logoSelected, setLogoSelected] = useState("");
  const [initialTargetAudience, setInitialTargetAudience] = useState([]);
  const options = [
    {
      value: "Mulheres interessadas em moda e tendências",
      label: "Mulheres interessadas em moda e tendências",
    },
    {
      value: "Mulheres interessadas em maquiagem e produtos de beleza",
      label: "Mulheres interessadas em maquiagem e produtos de beleza",
    },
    {
      value: "Homens jovens que buscam estilo e autenticidade",
      label: "Homens jovens que buscam estilo e autenticidade",
    },
  ];

  const history = useHistory();

  const schema = yup.object().shape({
    nome: yup
      .string()
      .min(1)
      .max(40, "Limite de caracteres ultrapassado!")
      .required("Campo obrigatório!"),
    // sobrenome: yup.string().min(1).max(255).required("Campo obrigatório!"),
    email: yup.string().email("Email inválido!").required("Campo obrigatório!"),
    // nascimento: yup.string().min(8).max(10).required("campo obrigatório!"),
    // sexo: yup.string(),
    nome_empresa: yup.string().required("Campo obrigatório"),
    celular: yup.string().min(15).max(15).required("campo obrigatório!"),
    // endereco: yup.string().min(1).max(40, "Limite de caracteres ultrapassado!").required("Campo obrigatório!"),
    // segmento: yup.string().min(1).max(40, "Limite de caracteres ultrapassado!").required("Campo obrigatório!"),
    // produto_servico: yup.string().min(1).max(40, "Limite de caracteres ultrapassado!").required("Campo obrigatório!"),
    // cpf_cnpj: yup.string().min(14).max(18).required("campo obrigatório!"),
    instagram: yup.string().required("Campo obrigatório"),
    password: yup
      .string()
      .min(4, "A senha deve conter no mínimo 8 caracteres!")
      .required("Campo obrigatório!"),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password")], "Senhas diferentes!")
      .required("Campo obrigatório!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const renderError = () => {
    if (Object.keys(errors).length > 0) {
      return (
        <div className="errorDiv">
          Algo deu errado! Confira os campos preenchidos e tente novamente!
        </div>
      );
    }
  };

  useEffect(() => {
    async function loadSements() {
      api
        .get("api/v1/segmentos/")
        .then((res) => {
          const { data } = res;
          if (data) {
            setSegments(data);
          }
        })
        .catch((err) => {
          toast.error("Ocorreu um problema ao buscar a lista de segmentos");
        });
    }
    loadSements();
    getPublicoAlvo();
  }, []);

  useEffect(() => {
    if (watch) {
      const { cpf_cnpj } = watch();
      if (cpf_cnpj) {
        if (cpf_cnpj.length >= 15) {
          setMasked("99.999.999/9999-99");
        } else if (cpf_cnpj.length <= 14) {
          setMasked("999.999.999-999");
        }
      }
    }
  }, [watch()]);

  const getPublicoAlvo = async () => {
    api
      .get("api/v1/publico_alvo/")
      .then((result) => {
        const { data } = result;
        if (data) {
          const _results = data.map((item) => {
            return {
              id: item.id,
              value: item.nome,
              label: item.nome,
            };
          });
          setInitialTargetAudience(_results);
        }
      })
      .catch((err) => {
        toast.error("Ocorreu um problema ao buscar a lista de público alvo");
      });
  };

  const registerNewClient = async (newClient) => {
    const regex = /\W|_/;

    let client = newClient;
    client.profile_picture = logoSelected ? logoSelected : "";
    client.ver_endereco = true;
    client.whatsapp = client.celular;
    client.telegram = client.celular;
    client.tel_fixo = "";
    client.nota = 1;
    client.sobrenome = "";
    client.nascimento = "";
    client.instagram = client.instagram;
    client.sexo = "";
    client.endereco = "";
    client.segmento_influence = segmentSelected;
    client.publico_alvo = targetAudience;
    client.produto_servico = "";
    client.cidade = cidadeSelected;
    client.estado = estadoSelected;

    if (regex.test(client.password) && client.password.length >= 8) {
      setIsLoading(true);
      api
        .post("/api/v1/clientes/", client)
        .then((res) => {
          toast.success("Cadastro realizado, cheque seu e-mail!");
          setIsLoading(false);
          return history.push("/login");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(
            "Ocorreu algum erro! Esse email ou nome já existe no sistema!"
          );
        });
    } else {
      toast.error(
        "A senha precisa ter pelo menos 8 digitos e um caractere especial, além de ter letras"
      );
    }
  };

  const handleFileChange = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    setIsPictureLoading(true);

    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    const url = await uploadFile("cliente", formData);

    setLogoSelected(url);

    setIsPictureLoading(false);
  };

  // const nextPage = () => {
  //   if( page < 4){
  //     const newPage = page + 1
  //     setPage(newPage)
  //   }
  // }

  // const prevPage = () => {
  //   if( page > 1){
  //     const newPage = page - 1
  //     setPage(newPage)
  //   }
  // }

  const handleNavigation = (path) => {
    return history.push(path);
  };

  const handleChange = (field, value) => {
    if (field === "regiao") {
      setEstados(regioesNomes.regioes.find((r) => r.nome === value).estados);
    } else if (field === "estado") {
      setCidades(estados.find((r) => r.nome === value).cidades);
      setEstadoSelected(value);
    } else if (field === "cidade") {
      setCidadeSelected(value);
    }
  };

  const handleChangeSegmento = (value) => {
    setSegmentSelected(value);
  };

  const handleChangeTargetAudience = (value) => {
    const _value = value.map((item) => item.label);
    setTargetAudience(_value);
  };

  useEffect(() => {
    if (auth) {
      return history.push("/dashboard");
    }
  }, []);

  const handleTypeRegister = (value) => {
    if (value === "influence") {
      window.open('https://linktr.ee/boraverapp', '_blank');
    } else {
      setTipo('anunciante');
    }
  }

  return (
    <div>
      {/* <Header /> */}
      <Content>
        <LeftSide page={page}>
          {!mensagem ? (
            tipo === "anunciante" ? (
              <Form onSubmit={handleSubmit(registerNewClient)}>
                <h2>Cadastre-se</h2>
                <div className="pageOne">
                  <LabelTipo>Escolha seu tipo de cadastro:</LabelTipo>
                  <TipoRadio>
                    <label htmlFor="anunciante">
                      <input
                        type="radio"
                        id="anunciante"
                        name="tipo"
                        checked={tipo === "anunciante"}
                        onChange={() => handleTypeRegister("anunciante")}
                      />
                      Anunciante/Empresa
                    </label>
                    <label htmlFor="influence">
                      <input
                        type="radio"
                        id="influence"
                        name="tipo"
                        checked={tipo === "influence"}
                        onChange={() => handleTypeRegister("influence")}
                      />
                      Criador de Conteúdo
                    </label>
                  </TipoRadio>
                  <div
                    style={{
                      color: "var(--purple)",
                      fontFamily: "Poppins,sans-serif",
                      fontSize: 14,
                      marginBottom: 5,
                      marginLeft: 2,
                    }}
                  >
                    Logo
                  </div>
                  <input
                    name="Logo"
                    label="Logo"
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <div
                      style={{ cursor: "pointer" }}
                      className="image-container"
                    >
                      <img
                        src={logoSelected ? logoSelected : Logo}
                        alt="client-profilepic"
                        height={50}
                        width={50}
                      />
                      {isPictureLoading ? (
                        <Spinner
                          positioned={true}
                          style={{ right: 10, bottom: 8 }}
                        />
                      ) : (
                        <img className="logo" src={EditIcon} />
                      )}
                    </div>
                  </label>
                  <Input
                    register={register}
                    name="nome"
                    label="Digite seu nome"
                    placeholder="Digite seu nome"
                    error={errors.nome?.message}
                  />
                  {/* <Input 
                register={register}
                name="sobrenome"
                label="Sobrenome"
                placeholder="Digite seu sobrenome"
                error={errors.sobrenome?.message}
              /> */}
                  {/* <div>
                <label>Sexo</label>
                <select {...register("sexo")}>
                  <option value="male">Masculino</option>
                  <option value="female">Feminino</option>
                  <option value="other">Outros</option>
                </select>
              </div> */}
                  {/* </div>

            <div className="pageTwo"> */}
                  <Input
                    register={register}
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Digite seu email"
                    error={errors.email?.message}
                  />
                  <Input
                    register={register}
                    mask="(99) 99999-9999"
                    name="celular"
                    label="Digite o número do seu celular"
                    placeholder="(xx) xxxxx-xxxx"
                    error={errors.celular?.message}
                  />
                  {/* <Input 
                register={register}
                name="endereco"
                label="Qual o seu endereço?"
                placeholder="Digite o seu endereço"
                error={errors.endereco?.message}
              />
            </div>

            <div className="pageThree"> */}
                  {/* <Input 
                register={register}
                name="segmento"
                label="Qual o segmento do seu negócio?"
                placeholder="Digite o seu segmento"
                error={errors.segmento?.message}
              />
              <Input 
                register={register}
                name="produto_servico"
                label="Qual o tipo de produto você trabalha?"
                placeholder="Digite o tipo do seu produto"
                error={errors.produto_servico?.message}
              /> */}
                  {/* <Input
                    register={register}
                    mask={masked}
                    maskChar={""}
                    name="cpf_cnpj"
                    label="Digite seu CPF ou o seu CNPJ"
                    placeholder="Apenas números"
                    error={errors.cpf_cnpj?.message}
                  /> */}
                  <Input
                    register={register}
                    name="nome_empresa"
                    label="Empresa"
                    placeholder="Digite o nome da sua Empresa"
                    error={errors.nome_empresa?.message}
                  />
                  <div className="custom-select">
                    <label>Nichos do criador de conteúdo:</label>{" "}
                    <span>(Selecione 03 que combinam com sua empresa)</span>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      // defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      name="select"
                      options={initialTargetAudience}
                      values={[]}
                      // formatOptionLabel={formatOptionLabel}
                      placeholder="Selecione uma opção"
                      onChange={(value) => handleChangeTargetAudience(value)}
                    />
                  </div>
                  <Input
                    register={register}
                    name="instagram"
                    label="Instagram"
                    placeholder="nomedeusuario"
                    describe="Colocar apenas o nome de usuário, sem o '@'."
                    error={errors.instagram?.message}
                  />
                  <label style={{ marginBottom: 4 }}>
                    Selecione seu Segmento:
                  </label>{" "}
                  <br />
                  <SelectCustom
                    onChange={({ target }) =>
                      handleChangeSegmento(Number(target.value))
                    }
                  >
                    <option>Selecione uma opção</option>
                    {segments.map((seg) => (
                      <option key={seg.id} value={seg.id}>
                        {seg.nome}
                      </option>
                    ))}
                  </SelectCustom>
                  <br />
                  <label style={{ marginBottom: 4 }}>
                    Selecione sua Região:
                  </label>{" "}
                  <SelectCustom
                    onChange={({ target }) =>
                      handleChange("regiao", target.value)
                    }
                  >
                    <option selected>Selecione uma opção</option>
                    {[
                      "Norte",
                      "Nordeste",
                      "Centro-Oeste",
                      "Sudeste",
                      "Sul",
                    ].map((regiao, index) => (
                      <option key={index} value={regiao}>
                        {regiao}
                      </option>
                    ))}
                  </SelectCustom>
                  <label style={{ marginBottom: 4 }}>
                    Selecione seu Estado:
                  </label>{" "}
                  <br />
                  <SelectCustom
                    onChange={({ target }) =>
                      handleChange("estado", target.value)
                    }
                  >
                    <option selected>Selecione uma opção</option>
                    {estados.map((regiao, index) => (
                      <option key={index} value={regiao.nome}>
                        {regiao.nome}
                      </option>
                    ))}
                  </SelectCustom>
                  {/* {payload.estado ? <> */}
                  <label style={{ marginBottom: 4 }}>
                    Selecione sua Cidade:
                  </label>{" "}
                  <br />
                  <SelectCustom
                    onChange={({ target }) =>
                      handleChange("cidade", target.value)
                    }
                  >
                    <option selected>Selecione uma opção</option>
                    {cidades.map((regiao, index) => (
                      <option key={index} value={regiao}>
                        {regiao}
                      </option>
                    ))}
                  </SelectCustom>
                  {/* </> : null} */}
                  {/* </div>

            <div className="pageFour"> */}
                  {/* <Input 
                register={register}
                mask={"99/99/9999"}
                name="nascimento"
                label="Digite a sua data de nascimento"
                placeholder="dd/mm/aaaa"
                error={errors.nascimento?.message}
              /> */}
                  <Input
                    register={register}
                    name="password"
                    type="password"
                    label="Senha"
                    placeholder="Digite sua senha"
                    error={errors.password?.message}
                  />
                  <Input
                    register={register}
                    name="passwordConfirm"
                    type="password"
                    label="Confirme sua senha"
                    placeholder="Confirme sua senha"
                    error={errors.passwordConfirm?.message}
                  />
                </div>

                {/* <div className="pageableButtons">
              <Button 
                setBackgroundColor={page === 1 ? "var(--lavender)" : "var(--purple)"}
                setColor="var(--white)"
                // large
                onClick={() => prevPage()}
              >
                Voltar
              </Button>
              <Button 
                setBackgroundColor={page === 4 ? "var(--lavender)" : "var(--purple)"}
                setColor="var(--white)"
                // large
                onClick={() => nextPage()}
              >
                Avançar
              </Button>
            </div> */}
                {renderError()}

                <span>
                  Já possui cadastro? Faça aqui seu{" "}
                  <a onClick={() => handleNavigation("/login")}>login</a>
                </span>
                <div className="btn-place">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <Button
                      setBackgroundColor="var(--purple)"
                      setColor="var(--white)"
                      large
                      type="submit"
                    >
                      Cadastrar
                    </Button>
                  )}
                </div>
              </Form>
            ) : (
              <InfluenceForm
                setTipo={setTipo}
                tipo={tipo}
                segments={segments}
                setMensagem={setMensagem}
              />
            )
          ) : (
            <MensagemContainer>
              <h2>Cadastro realizado com sucesso</h2>
              <p>Você irá receber um email com os próximos passos.</p>
              <p>
                Não esqueça de baixar o nosso app nas lojas{" "}
                <a href="https://play.google.com/store/apps/details?id=boraver.com">
                  Google Play
                </a>{" "}
                ou{" "}
                <a href="https://apps.apple.com/br/app/boraver-influencer/id6444215783">
                  Apple Store
                </a>{" "}
                para acessar sua conta.
              </p>
            </MensagemContainer>
          )}
        </LeftSide>
        <RightSide>
          <img src={SignupPic} alt="signup-boraver" />
        </RightSide>
      </Content>
      {/* <Footer /> */}
    </div>
  );
};
export default Signup;

const InfluenceForm = ({ setTipo, tipo, segments, setMensagem }) => {
  const history = useHistory();
  const [masked, setMasked] = useState("999.999.999-999");
  const [payload, setPayload] = useState({
    nome: "",
  });
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [loading, setLoading] = useState(false);

  const [secondSocialMedia, setSecondSocialMedia] = useState("");
  const [thirdSocialMedia, setThirdSocialMedia] = useState("");
  const [showSecondSocialMedia, setShowSecondSocialMedia] = useState(false);
  const [showThirdSocialMedia, setShowThirdSocialMedia] = useState(false);
  const [segmentos, setSegmentos] = useState([]);

  // useEffect(() => {
  //   if (segments) {
  //     const _seg = segments.map((item) => {
  //       return {
  //         id: item.id,
  //         nome: item.nome,
  //         value: item.nome,
  //         label: item.nome,
  //       };
  //     });
  //     setSegmentos(_seg);
  //   }
  // }, [segments]);

  const register = () => {
    if (!payload.nome) {
      toast.error("nome é obrigatório");
      return;
    } else if (payload.nome.split(" ").length > 1) {
      toast.error("nome não pode ter espaço");
      return;
    } else if (!payload.email) {
      toast.error("email é obrigatório");
      return;
    } else if (!payload.cpf) {
      toast.error("CPF é obrigatório");
      return;
    } else if (!payload.password || payload.password.length < 8) {
      toast.error("A senha precisa ter pelo menos 8 digitos");
      return;
    } else if (
      !payload.re_password ||
      payload.password !== payload.re_password
    ) {
      toast.error("As senhas precisam idênticas");
      return;
    } else if (!payload.rede_principal) {
      toast.error("Rede principal é obrigatório");
      return;
    } else if (!payload.instagram && payload.rede_principal === "Instagram") {
      toast.error("Instagram é obrigatório, pois é sua rede principal");
      return;
    } else if (
      !payload.qtd_instagram &&
      payload.rede_principal === "Instagram"
    ) {
      toast.error(
        "Quantidade Seguidores Instagram obrigatório. É sua rede principal"
      );
      return;
    } else if (!payload.youtube && payload.rede_principal === "YouTube") {
      toast.error("Youtube é obrigatório, pois é sua rede principal");
      return;
    } else if (!payload.qtd_youtube && payload.rede_principal === "YouTube") {
      toast.error(
        "Quantidade Inscritos Youtube obrigatório. É sua rede principal"
      );
      return;
    } else if (!payload.tiktok && payload.rede_principal === "Tiktok") {
      toast.error("Tiktok é obrigatório, pois é sua rede principal");
      return;
    } else if (!payload.qtd_tiktok && payload.rede_principal === "Tiktok") {
      toast.error(
        "Quantidade Seguidores Tiktok obrigatório. É sua rede principal"
      );
      return;
    // } else if (payload.qtd_instagram !== "" && payload.qtd_instagram < 3000) {
    //   toast.error("Seu Instagram precisa ter 3000 ou mais seguidores");
    //   return;
    // } else if (payload.qtd_youtube !== "" && payload.qtd_youtube < 3000) {
    //   toast.error("Seu YouTube precisa ter 3000 ou mais inscritos");
    //   return;
    // } else if (payload.qtd_tiktok !== "" && payload.qtd_tiktok < 3000) {
    //   toast.error("Seu Tiktok precisa ter 3000 ou mais seguidores");
    //   return;
    }

    setLoading(true);

    api
      .post("/api/v3/veremail/", {
        email: payload.email,
        name: payload.nome,
      })
      .then(function (response) {
        const { re_password, ...rest } = payload;
        api
          .post("/api/v1/influencers/", {
            ...rest,
            profile_picture: "",
            nomecompleto: "",
            celular: "",
            telegram: "",
            tel_fixo: "",
            nascimento: "",
            vlw_minimo: "",
            media_kit: "",
            cpf: payload.cpf,
            cnpj: "",
            redesocial_principal: payload.rede_principal,
            whatsapp: payload.whatsapp ?? "",
            sexo: "",
            regiao: payload.regiao ?? "",
            estado: payload.estado ?? "",
            cidade: payload.cidade ?? "",
            instagram: payload.instagram ? payload.instagram : "",
            segmentos_order: segmentos,
            qtd_intagram: payload.qtd_instagram
              ? onlyNumber(payload.qtd_instagram)
              : 0,
            tiktok: payload.tiktok ? payload.tiktok : "",
            qtd_tiktok: payload.qtd_tiktok ? onlyNumber(payload.qtd_tiktok) : 0,
            youtube: payload.youtube ? payload.youtube : "",
            qtd_youtube: payload.qtd_youtube
              ? onlyNumber(payload.qtd_youtube)
              : 0,
            agencia_banco: "",
            conta_banco: "",
            saldo: 0,
            pix: "",
            ativo: false,
          })
          .then(function (response) {
            setMensagem(true);
            setLoading(false);
          })
          .catch(function (error) {
            try {
              api.delete(`/api/v3/deletuser/${payload.email}`);
            } catch (error) {
              console.error(error);
            }
            console.error(error);
            if (error.response.status === 400) {
              toast.error(
                "Erro ao cadastrar, verifique seus dados e tente novamente. Se o problema persistir contate o suporte técnico"
              );
            } else if (error.response.status === 500) {
              console.error(error.response);
              toast.error(
                "Estamos com problemas técnico. Por favor, tente mais tarde"
              );
            } else {
              toast.error(
                "Problema desconhecido. Por favor, contate o suporte"
              );
            }
            setLoading(false);
          });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.details);
        } else if (error.response.status === 500) {
          toast.error(
            "Estamos com problemas técnico. Por favor, tente mais tarde"
          );
        } else {
          toast.error("Problema desconhecido. Por favor, contate o suporte");
        }
        setLoading(false);
      });
  };

  const handleChange = (field, value, order) => {

    if (field === "nome") {
      const _nome = value.replace(/\s/g, ""); //removendo espaço em branco
      setPayload({ ...payload, [field]: _nome });
    } else if (field === "rede_principal") {
      setPayload({
        ...payload,
        rede_principal: value,
        instagram: "",
        qtd_instagram: "",
        tiktok: "",
        qtd_tiktok: "",
        youtube: "",
        qtd_youtube: "",
      });

      setShowSecondSocialMedia(false);
      setShowThirdSocialMedia(false);
      setSecondSocialMedia("");
      setThirdSocialMedia("");
    } else if(field === "segmentos") {
      const _value = value.map(item => item.id)
      setPayload({
        ...payload,
        [field]: _value
      })
    } else {
      setPayload({ ...payload, [field]: value });
    }
    if(field === "segmentos_order") {
      const _segments = segments.find(item => item.id === value)
      const newSegmentos = [...segmentos]

      if (order >= 0 && order < newSegmentos.length) {
        newSegmentos[order] = {id: _segments.id, nome: _segments.nome, ordem: order+1};
      } else {
        newSegmentos.splice(order, 0, {id: _segments.id, nome: _segments.nome, ordem: order+1}); // Adicione o novo objeto na posição
      }
      setSegmentos(newSegmentos)

    }

    if (field === "regiao") {
      setEstados(regioesNomes.regioes.find((r) => r.nome === value).estados);
    } else if (field === "estado") {
      setCidades(estados.find((r) => r.nome === value).cidades);
    }
    
  };

  const handleTypeRegister = (value) => {
    if (value === "influence") {
      window.open('https://linktr.ee/boraverapp', '_blank');
    } else {
      setTipo('anunciante');
    }
  }

  return (
    <Form onSubmit={() => {}}>
      <h2>Cadastre-se</h2>
      <div className="pageOne">
        <LabelTipo>Escolha seu tipo de cadastro:</LabelTipo>
        <TipoRadio>
          <label htmlFor="anunciante">
            <input
              type="radio"
              id="anunciante"
              name="tipo"
              checked={tipo === "anunciante"}
              onChange={() => handleTypeRegister("anunciante")}
            />
            Anunciante/Empresa
          </label>
          <label htmlFor="influence">
            <input
              type="radio"
              id="influence"
              name="tipo"
              checked={tipo === "influence"}
              onChange={() => handleTypeRegister("influence")}
            />
            Criador de Conteúdo
          </label>
        </TipoRadio>
        <Input
          register={() => {}}
          name="nome"
          label="Nome"
          placeholder="NomeDoUsuario"
          value={payload.nome}
          onChange={({ target }) => handleChange("nome", target.value)}
        />
        <Input
          register={() => {}}
          name="email"
          label="Email"
          type="email"
          placeholder="Digite seu email"
          onChange={({ target }) => handleChange("email", target.value)}
        />
        <Column>
          <label htmlFor="cpf">CPF</label>
          <div>
            <InputMask
              mask={"999.999.999-99"}
              name="cpf"
              id="cpf"
              register={() => {}}
              placeholder="Digite seu CPF"
              onChange={({ target }) =>
                handleChange("cpf", target.value)
              }
            />
          </div>
        </Column>
        <Input
          register={() => {}}
          mask="(99) 99999-9999"
          name="celular"
          label="Digite o número do seu celular"
          placeholder="(xx) xxxxx-xxxx"
          onChange={({ target }) => handleChange("whatssap", target.value)}
        />
        <Input
          register={() => {}}
          name="password"
          type="password"
          label="Senha"
          placeholder="Digite sua senha"
          onChange={({ target }) => handleChange("password", target.value)}
        />
        <Input
          register={() => {}}
          name="passwordConfirm"
          type="password"
          label="Confirme sua senha"
          placeholder="Confirme sua senha"
          onChange={({ target }) => handleChange("re_password", target.value)}
        />
          <label style={{ marginBottom: 4 }}>Selecione seu Segmento Primário:</label>{" "}
          <SelectCustom
            onChange={({ target }) =>
              handleChange("segmentos_order", target.value, 0)
            }
          >
            <option>Selecione uma opção</option>
            {segments.map((seg) => (
              <option key={seg.id} value={seg.id}>
                {seg.nome}
              </option>
            ))}
          </SelectCustom>
          <label style={{ marginBottom: 4 }}>Selecione seu Segmento Secundário:</label>{" "}
          <SelectCustom
            onChange={({ target }) =>
              handleChange("segmentos_order", target.value, 1)
            }
          >
            <option>Selecione uma opção</option>
            {segments.map((seg) => (
              <option key={seg.id} value={seg.id}>
                {seg.nome}
              </option>
            ))}
          </SelectCustom>
          <label style={{ marginBottom: 4 }}>Selecione seu Segmento Terciário:</label>{" "}
          <SelectCustom
            onChange={({ target }) =>
              handleChange("segmentos_order", target.value, 2)
            }
          >
            <option>Selecione uma opção</option>
            {segments.map((seg) => (
              <option key={seg.id} value={seg.id}>
                {seg.nome}
              </option>
            ))}
          </SelectCustom>
          {/* <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            // defaultValue={[colourOptions[4], colourOptions[5]]}
            isMulti
            name="select"
            options={segmentos}
            values={[]}
            // formatOptionLabel={formatOptionLabel}
            placeholder="Selecione uma opção"
            onChange={(value) => handleChange("segmentos", value)}
          /> */}

        <label style={{ marginBottom: 4, marginTop: 10 }}>Rede Social Principal:</label> <br />
        <SelectCustom
          onChange={({ target }) => {
            handleChange("rede_principal", target.value);
          }}
        >
          <option>Selecione uma opção</option>
          {["Instagram", "YouTube", "Tiktok"].map((rede, index) => (
            <option key={index} value={rede}>
              {rede}
            </option>
          ))}
        </SelectCustom>
        {payload.rede_principal === "Instagram" ? (
          <>
            <Input
              register={() => {}}
              name="instagram"
              label="Seu usuário instagram:"
              placeholder="nomedeusuario"
              describe="Colocar apenas o nome de usuário, sem o '@'."
              onChange={({ target }) => handleChange("instagram", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_instagram"
              type="number"
              label="Nº Seguidores Instagram"
              onChange={({ target }) =>
                handleChange("qtd_instagram", target.value)
              }
            />
          </>
        ) : null}
        {payload.rede_principal === "YouTube" ? (
          <>
            <Input
              register={() => {}}
              name="youtube"
              label="Link canal Youtube:"
              placeholder=""
              onChange={({ target }) => handleChange("youtube", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_youtube"
              type="number"
              label="Nº Inscrito Youtube"
              onChange={({ target }) =>
                handleChange("qtd_youtube", target.value)
              }
            />
          </>
        ) : null}
        {payload.rede_principal === "Tiktok" ? (
          <>
            <Input
              register={() => {}}
              name="tiktok"
              label="Seu usuário tiktok"
              placeholder="@usuariotiktok"
              onChange={({ target }) => handleChange("tiktok", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_tiktok"
              type="number"
              label="Nº Seguidores TikTok"
              onChange={({ target }) =>
                handleChange("qtd_tiktok", target.value)
              }
            />
          </>
        ) : null}
        {showSecondSocialMedia ? (
          <>
            <label style={{ marginBottom: 4 }}>Rede Social Secundária:</label>{" "}
            <br />
            <SelectCustom
              onChange={({ target }) => {
                setSecondSocialMedia(target.value);
              }}
            >
              <option>Selecione uma opção</option>
              {["Instagram", "YouTube", "Tiktok"]
                .filter((socialMedia) => socialMedia !== payload.rede_principal)
                .map((rede, index) => (
                  <option key={index} value={rede}>
                    {rede}
                  </option>
                ))}
            </SelectCustom>
          </>
        ) : null}
        {secondSocialMedia === "Instagram" ? (
          <>
            <Input
              register={() => {}}
              name="instagram"
              label="Seu usuário instagram:"
              placeholder="@usuarioinstagram"
              onChange={({ target }) => handleChange("instagram", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_instagram"
              type="number"
              label="Nº Seguidores Instagram"
              onChange={({ target }) =>
                handleChange("qtd_instagram", target.value)
              }
            />
          </>
        ) : null}
        {secondSocialMedia === "YouTube" ? (
          <>
            <Input
              register={() => {}}
              name="youtube"
              label="Link canal Youtube:"
              placeholder=""
              onChange={({ target }) => handleChange("youtube", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_youtube"
              type="number"
              label="Nº Inscrito Youtube"
              onChange={({ target }) =>
                handleChange("qtd_youtube", target.value)
              }
            />
          </>
        ) : null}
        {secondSocialMedia === "Tiktok" ? (
          <>
            <Input
              register={() => {}}
              name="tiktok"
              label="Seu usuário tiktok"
              placeholder="@usuariotiktok"
              onChange={({ target }) => handleChange("tiktok", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_tiktok"
              type="number"
              label="Nº Seguidores TikTok"
              onChange={({ target }) =>
                handleChange("qtd_tiktok", target.value)
              }
            />
          </>
        ) : null}
        {showThirdSocialMedia ? (
          <>
            <label style={{ marginBottom: 4 }}>Rede Social Terciária:</label>
            <br />
            <SelectCustom
              onChange={({ target }) => {
                setThirdSocialMedia(target.value);
              }}
            >
              <option>Selecione uma opção</option>
              {["Instagram", "YouTube", "Tiktok"]
                .filter(
                  (socialMedia) =>
                    socialMedia !== payload.rede_principal &&
                    socialMedia !== secondSocialMedia
                )
                .map((rede, index) => (
                  <option key={index} value={rede}>
                    {rede}
                  </option>
                ))}
            </SelectCustom>
          </>
        ) : null}
        {thirdSocialMedia === "Instagram" ? (
          <>
            <Input
              register={() => {}}
              name="instagram"
              label="Seu usuário instagram:"
              placeholder="@usuarioinstagram"
              onChange={({ target }) => handleChange("instagram", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_instagram"
              type="number"
              label="Nº Seguidores Instagram"
              onChange={({ target }) =>
                handleChange("qtd_instagram", target.value)
              }
            />
          </>
        ) : null}
        {thirdSocialMedia === "YouTube" ? (
          <>
            <Input
              register={() => {}}
              name="youtube"
              label="Link canal Youtube:"
              placeholder=""
              onChange={({ target }) => handleChange("youtube", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_youtube"
              type="number"
              label="Nº Inscrito Youtube"
              onChange={({ target }) =>
                handleChange("qtd_youtube", target.value)
              }
            />
          </>
        ) : null}
        {thirdSocialMedia === "Tiktok" ? (
          <>
            <Input
              register={() => {}}
              name="tiktok"
              label="Seu usuário tiktok"
              placeholder="@usuariotiktok"
              onChange={({ target }) => handleChange("tiktok", target.value)}
            />
            <Input
              register={() => {}}
              name="qtd_tiktok"
              type="number"
              label="Nº Seguidores TikTok"
              onChange={({ target }) =>
                handleChange("qtd_tiktok", target.value)
              }
            />
          </>
        ) : null}
        {payload.rede_principal && !showThirdSocialMedia ? (
          <>
            <button
              type="button"
              style={{
                cursor: "pointer",
                border: "2px solid var(--purple)",
                borderRadius: "10px",
                padding: "5px",
                background: "transparent",
                color: "var(--purple)",
                marginBottom: "10px",
              }}
              onClick={() => {
                if (!showSecondSocialMedia) {
                  setShowSecondSocialMedia(true);
                } else if (showSecondSocialMedia) {
                  setShowThirdSocialMedia(true);
                }
              }}
            >
              <strong>Cadastrar nova rede social</strong>
            </button>
            <br />
          </>
        ) : null}
        <label style={{ marginBottom: 4 }}>Selecione sua Região:</label> <br />
        <SelectCustom
          onChange={({ target }) => handleChange("regiao", target.value)}
        >
          <option selected>Selecione uma opção</option>
          {["Norte", "Nordeste", "Centro-Oeste", "Sudeste", "Sul"].map(
            (regiao, index) => (
              <option key={index} value={regiao}>
                {regiao}
              </option>
            )
          )}
        </SelectCustom>
        <label style={{ marginBottom: 4 }}>Selecione seu Estado:</label> <br />
        <SelectCustom
          onChange={({ target }) => handleChange("estado", target.value)}
        >
          <option selected>Selecione uma opção</option>
          {estados.map((regiao, index) => (
            <option key={index} value={regiao.nome}>
              {regiao.nome}
            </option>
          ))}
        </SelectCustom>
        {payload.estado ? (
          <>
            <label style={{ marginBottom: 4 }}>Selecione sua Cidade:</label>{" "}
            <br />
            <SelectCustom
              onChange={({ target }) => handleChange("cidade", target.value)}
            >
              <option selected>Selecione uma opção</option>
              {cidades.map((regiao, index) => (
                <option key={index} value={regiao}>
                  {regiao}
                </option>
              ))}
            </SelectCustom>
          </>
        ) : null}
      </div>

      <div className="btn-place">
        {loading ? (
          <Spinner />
        ) : (
          <Button
            setBackgroundColor="var(--purple)"
            setColor="var(--white)"
            large
            type="button"
            onClick={register}
          >
            Cadastrar
          </Button>
        )}
      </div>
    </Form>
  );
};
