import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Logo from "../../assets/user.webp";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import {
  ClientProfileDetails,
  Container,
  Content,
  Button,
  Card,
  DivColumn,
  DivRow,
} from "./styles";
import api from "../../services";
import EditIcon from "../../assets/icon.svg";
import { uploadFile } from "../../services/cliente/upload";
import { updateProflePicture } from "../../services/cliente/updateProfilePicture";
import { formatPhoneNumber, onlyNumber } from "../../utils/helpers";
import { toast } from "react-toastify";
import { ButtonOption, SelectCustom } from "../Register/styes";
import { regioes as regioesNomes } from "../../utils/helpers";

const ClientProfile = () => {
  const { setAuth, auth } = useAuth();
  const { userData, setUserData, clientId, setUserId } = useClient();
  const history = useHistory();
  // const isPictureLoading = useRef(false)
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    nome_empresa: userData?.nome_empresa,
    nome: userData?.nome,
    // sobrenome: userData?.sobrenome,
    email: userData?.email,
    celular: userData?.celular,
    password: '',
    new_password: '',
    confirm_password: ''
  });
  const [cidades, setCidades] = useState([]);
  const [estados, setEstados] = useState([]);
  const [cidadeSelected, setCidadeSelected] = useState(userData?.cidade);
  const [estadoSelected, setEstadoSelected] = useState(userData?.estado);
  const [segmentos, setSegmentos] = useState([]);
  const [segmentoSelected, setSegmentoSelected] = useState(userData?.segmento);
  const [publicoAlvo, setPublicoAlvo] = useState([]);
  const [publicoAlvoSelected, setPublicoAlvoSelected] = useState([]);

  const handleFileChange = async (e) => {
    if (e.target.files.length < 1) {
      return;
    }

    // isPictureLoading.current = true;
    setIsPictureLoading(true);

    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    const url = await uploadFile("cliente", formData);

    const response = await updateProflePicture(clientId, url);

    setUserData(response);

    setIsPictureLoading(false);
    // isPictureLoading.current = false;
  };

  useEffect(() => {
    if (!auth) {
      return history.push("/");
    }
    // getSolicitations();
    return;
  }, []);

  useEffect(() => {
    if(userData) {
      const publico = userData.publico_alvo.map(item => item.nome)
      setPublicoAlvoSelected(publico)
    }
  }, [userData])

  useEffect(() => {
    if(userData && segmentos.length > 0) {
      const segmento = userData.segmento;
      if (segmento) {
        const _segmento = segmentos.find((item) => item.nome === segmento)
        if (_segmento) setSegmentoSelected(_segmento.id)
      }
    }
  }, [userData, segmentos])

  useEffect(() => {
    getSegmentos();
    getPublicoAlvo();
  }, []);

  useEffect(() => {
    const estadosOrdenados = regioesNomes.regioes
      .flatMap((regiao) => regiao.estados.map((estado) => estado))
      .sort();

    setEstados(estadosOrdenados);
  }, []);

  useEffect(() =>{
    if (estados.length > 0 && estadoSelected && estadoSelected !== "Selecione Uma Opção"){
      setCidades(estados.find((r) => r.nome === estadoSelected).cidades);
    } else if (!estadoSelected || estadoSelected === "Selecione Uma Opção") {
      setCidades([]);
    }
  }, [estados, estadoSelected])

  const getSegmentos = async () => {
    try {
      const result = await api.get("api/v1/segmentos/");
      if (result) {
        const { data } = result;
        if (data) {
          setSegmentos(data);
        }
      }
    } catch (error) {
      toast.error("Ocorreu um problema ao buscar a lista de segmentos");
    }
  };

  const getPublicoAlvo = async () => {
    try {
      const result = await api.get("api/v1/publico_alvo/");
      if (result) {
        const { data } = result;
        if (data) {
          const _results = data.map((item) => {
            return {
              id: item.id,
              value: item.nome,
              label: item.nome,
            };
          });
          setPublicoAlvo(_results);
        }
      }
    } catch (error) {
      toast.error("Ocorreu um problema ao buscar a lista de público alvo");
    }
  };

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleChange = (key, value) => {
    if (key === "estado") {
      setEstadoSelected(value);
    } else if (key === "cidade") {
      setCidadeSelected(value);
    }
  };

  const toggleSegmentoSelection = (id) => {
    setSegmentoSelected(id);
  };

  const togglePublicoAlvoSelection = (name) => {
    setPublicoAlvoSelected((prevSelected) => {
      if (prevSelected.includes(name)) {
        // Se o ID já está selecionado, remova-o
        return prevSelected.filter((selectedId) => selectedId !== name);
      } else {
        // Se não está selecionado, adicione-o
        return [...prevSelected, name];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      if (validate()) {
        setLoading(true);

        const cliente = {
          nome_empresa: payload?.nome_empresa,
          nome: payload?.nome,
          // sobrenome: payload?.sobrenome,
          email: payload?.email,
          celular: payload?.celular ? onlyNumber(payload.celular) : '',
          cidade: cidadeSelected,
          estado: estadoSelected,
          publico_alvo: publicoAlvoSelected,
          segmento_influence: segmentoSelected,
          password: payload?.password.trim(),
          new_password: payload?.new_password.trim()
        }

        const result = await api.patch(`/api/v1/clientes/${userData.id}/`, cliente,  {
          headers: {
            Authorization: `Bearer ${auth}`
          }
        });
        if (result) {
          toast.success("Cadastro atualizado!");
          setLoading(false);
          setUserData(result.data);
          history.push("/dashboard");
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.mensagem) {
        toast.error(`${error.response.data.mensagem}`);
      } else {
        toast.error(`${error}`);
      }
    }
  }

  const validate = () => {
    if (!payload.nome_empresa) {
      toast.error("O campo nome da empresa precisa ser preenchido");
      return false;
    }
    if (!payload.nome) {
      toast.error("O campo nome precisa ser preenchido");
      return false;
    }
    // if (!payload.sobrenome) {
    //   toast.error("O campo sobrenome precisa ser preenchido");
    //   return false;
    // }
    if (!payload.email) {
      toast.error("O campo email precisa ser preenchido");
      return false;
    }
    if (!payload.celular) {
      toast.error("O campo celular precisa ser preenchido");
      return false;
    }
    if (payload.new_password){
      if (!payload.password){
        toast.error("O campo senha atual precisa ser preenchido");
        return false;
      }
      if (payload.new_password !== payload.confirm_password) {
        toast.error("Confirmar senha não pode ser diferente de nova senha");
        return false;
      }
    }
    return true;
  }

  return (
    <Container>
      <Content>
        {!!userData && (
          <Card>
            <ClientProfileDetails>
              <DivColumn>
                <label style={{ justifyContent: "center" }}>
                  Sua Foto de Perfil
                </label>
              </DivColumn>
              <input
                type="file"
                accept="image/*"
                id="contained-button-file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label className="file" htmlFor="contained-button-file">
                <div style={{ cursor: "pointer" }} className="image-container">
                  <img
                    src={
                      userData.profile_picture
                        ? userData.profile_picture.replace(
                            "base64:",
                            "data:image/png;base64,"
                          )
                        : Logo
                    }
                    alt="client-profilepic"
                  />
                  {isPictureLoading ? (
                    <Spinner positioned={true} />
                  ) : (
                    <img className="logo" src={EditIcon} />
                  )}
                </div>
              </label>

              <DivColumn style={{ marginTop: 20 }}>
                <label htmlFor="nome_empresa">Nome da Empresa</label>
                <input
                  value={payload?.nome_empresa}
                  type="text"
                  name="nome_empresa"
                  id="nome_empresa"
                  required
                  onChange={({ target }) =>
                    handlePayload("nome_empresa", target.value)
                  }
                />
              </DivColumn>
              <DivColumn>
                <label htmlFor="nome">Seu Nome Completo</label>
                <input
                  value={payload?.nome}
                  type="text"
                  name="nome"
                  id="nome"
                  required
                  autoComplete="false"
                  onChange={({ target }) => handlePayload("nome", target.value)}
                />
              </DivColumn>
              {/* <DivColumn>
                <label htmlFor="sobrenome">Seu Sobrenome</label>
                <input
                  value={payload?.sobrenome}
                  type="text"
                  name="sobrenome"
                  id="sobrenome"
                  required
                  autoComplete="false"
                  onChange={({ target }) =>
                    handlePayload("sobrenome", target.value)
                  }
                />
              </DivColumn> */}
              <DivColumn>
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  autoComplete="false"
                  value={payload?.email}
                  onChange={({ target }) =>
                    handlePayload("email", target.value)
                  }
                />
              </DivColumn>
              <DivColumn>
                <label htmlFor="celular">Celular</label>
                <input
                  type="tel"
                  name="celular"
                  id="celular"
                  required
                  autoComplete="false"
                  value={
                    payload?.celular ? formatPhoneNumber(payload.celular) : ""
                  }
                  onChange={({ target }) =>
                    handlePayload("celular", target.value)
                  }
                />
              </DivColumn>
              <DivColumn>
                <label style={{ marginBottom: 4 }}>Selecione seu Estado:</label>{" "}
                <SelectCustom
                  required
                  value={estadoSelected}
                  onChange={({ target }) => handleChange("estado", target.value)}
                  style={{ marginBottom: 0 }}
                >
                  <option selected>Selecione Uma Opção</option>
                  {estados.map((regiao, index) => (
                    <option key={index} value={regiao.nome}>
                      {regiao.nome}
                    </option>
                  ))}
                </SelectCustom>
              </DivColumn>
              <DivColumn style={{ marginBottom: 20 }}>   
                <label style={{ marginBottom: 4 }}>Selecione sua Cidade:</label>{" "}
                <SelectCustom
                  required
                  value={cidadeSelected}
                  onChange={({ target }) => handleChange("cidade", target.value)}
                >
                  <option selected>Selecione Uma Opção</option>
                  {cidades.map((regiao, index) => (
                    <option key={index} value={regiao}>
                      {regiao}
                    </option>
                  ))}
                </SelectCustom>
              </DivColumn>
              <DivColumn>
                  <label htmlFor="categorias">
                  Selecione as categorias que melhor te representam
                </label>
                <DivRow>
                  {publicoAlvo.length
                    ? publicoAlvo.map((item) => (
                        <ButtonOption
                          key={item.id}
                          active={publicoAlvoSelected.includes(item.label)}
                          onClick={() => togglePublicoAlvoSelection(item.label)}
                        >
                          {item.value}
                        </ButtonOption>
                      ))
                    : null}
                </DivRow>
              </DivColumn>
              <DivColumn>
                <label htmlFor="categorias">
                  Selecione o segmento que melhor te representam
                </label>
                <DivRow>
                  {segmentos.length
                    ? segmentos.map((item) => (
                        <ButtonOption
                          key={item.id}
                          active={segmentoSelected === item.id}
                          onClick={() => toggleSegmentoSelection(item.id)}
                        >
                          {item.nome}
                        </ButtonOption>
                      ))
                    : null}
                </DivRow>
              </DivColumn>
              <DivColumn>
                <label htmlFor="password">Senha Atual</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="false"
                  value={
                    payload?.password
                  }
                  onChange={({ target }) =>
                    handlePayload("password", target.value)
                  }
                />
              </DivColumn>
              <DivColumn>
                <label htmlFor="new_password">Nova Senha</label>
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  value={
                    payload?.new_password
                  }
                  onChange={({ target }) =>
                    handlePayload("new_password", target.value)
                  }
                />
              </DivColumn>
              <DivColumn>
                <label htmlFor="confirm_password">Confirmar Nova Senha</label>
                <input
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  value={
                    payload?.confirm_password
                  }
                  onChange={({ target }) =>
                    handlePayload("confirm_password", target.value)
                  }
                />
              </DivColumn>
              <Button
                setBackgroundColor="var(--yellow)"
                setColor="var(--black-50)"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                Salvar Alterações
              </Button>
            </ClientProfileDetails>
          </Card>
        )}
      </Content>
    </Container>
  );
};
export default ClientProfile;
