import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white);
  ${({ isAuth }) => (!isAuth ? 'box-shadow: 0 4px 4px 0 var(--black)' : null)};
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: ${(props) => (props.isAuth ? 'fixed' : 'static')};
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  max-width: 100%;
  padding: 12px 0;
  transition: 0.2 ease-in-out;
  z-index: 100;
`;

export const Content = styled.div`
  color: var(--white);
  width: 96vw;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
  overflow-y: hidden;

  .left-side {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    svg {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
    .logoDiv {
      margin: 0;
      width: 200px;
      display: flex;
      justify-content: center;
      margin-left: 22px;

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }

  .links {
    a {
      color: var(--black);
      margin-inline: 10px;
      text-decoration: none;
    }
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 24px;
    margin-left: 6px;
  }

  .right-side {
    display: flex;
    align-items: center;
    .notifications-place {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .unread-notifications {
        border-radius: 100%;
        width: 15px;
        height: 15px;
        background-color: var(--red);
        font-size: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
        position: relative;
        z-index: 1;
        top: -10px;
        left: -20px;
      }
      .bell-icon {
        margin-right: 10px;
        cursor: pointer;
        :hover {
          color: var(--blue);
        }
      }
    }

    & > img {
      border-radius: 50%;
      border: none;
      box-shadow: 0 0 4px 1px #ffffff;
      margin: 4px;
      object-fit: cover;
    }
  }

  @media (max-width: 960px) {
    width: 96dvw;
    .left-side > .logoDiv {
      width: 160px;
    }
  }

  @media (max-width: 810px) {
    .left-side > .logoDiv {
      width: 120px;
    }
  }

  @media (max-width: 765px) {
    .links {
      display: none;
    }

    .hamburger {
      display: block;
    }
  }

  @media (max-width: 490px) {
    width: 100%;
  }

  @media (max-width: 375px) {
    width: 100dvw;
    .left-side > .logoDiv {
      width: 100px;
    }
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: ${(props) => (props.isMenuOpen ? '0' : '-100%')};
  width: 30%;
  height: 100%;
  background-color: var(--white);
  box-shadow: -4px 0 4px 0 var(--black);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  transition: right 0.3s ease-in-out;
  z-index: 1000;

  a {
    margin: 1rem 0;
    color: var(--black);
    text-decoration: none;
  }

  @media (max-width: 475px) {
    width: 50%;
  }
`;

export const RightSideWrapper = styled.div`
  position: relative;
  /* width: 170px; */
`;

export const DivImage = styled.div`
  cursor: pointer;
  
  img {
    border: 1px solid var(--black);
    border-radius: 50%;
    height: 60px;
    width: 60px;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    height: 50px;
    width: 50px;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

export const DropdownMenu = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: 82px;
  right: 30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 150px;
`;

export const DropdownItem = styled.div`
  padding: 10px 20px;
  color: #333;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;