import styled from "styled-components";

export const DivZap = styled.a`
  position: fixed;
  display: flex;
  bottom: 40px;
  background-color: #29a71a;
  border-radius: 50%;
  right: 30px;
  animation: shadow-pulse 1.2s infinite;
  width: 70px;
  height: 70px;

  @keyframes shadow-pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(40, 167, 26, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(40, 167, 26, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(40, 167, 26, 0);
    }
  }

  span {
    background-color: var(--color-button-red);
    color: #ffffff;
    font-weight: bold;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    font-size: 13px;
    top: -1px;
    border-radius: 50%;
    text-align: center;
  }
`;

export const Image = styled.img`
  display: inline-block;
  width: 70px;
  height: 70px;
  margin-top: -1px;
  margin-left: 1px;
`;
