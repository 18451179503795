import React from 'react';
import { DivZap, Image } from './styles';
import ImgZap from '../../assets/whatsapp.png'

const PageWrapper = ({ children, showDivZap = true }) => (
  <>
    {children}
    {showDivZap && (
      <DivZap
        target="_blank"
        href="https://api.whatsapp.com/send?phone=559884329987&text=Ol%C3%A1,%20tudo%20bom?%20%0AGostaria%20de%20tirar%20uma%20d%C3%BAvida%20sobre%20o%20Boraver."
      >
        <Image src={ImgZap} />
      </DivZap>
    )}
  </>
);

export default PageWrapper;
