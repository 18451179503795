import styled from "styled-components";

export const Container = styled.div`
  /* flex: 0 100%; */
  /* min-height: fit-content; */
  height: auto;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.from === "receiver" ? "flex-start" : "flex-end"};
  padding: 10px;
  .profile {
    border: 1px solid var(--mediumgray);
    border-radius: 50%;
    margin: 0px 12px;
    height: 60px;
    min-height: 60px;
    width: 60px;
    min-width: 60px;
    object-fit: cover;
    order: ${(props) => (props.from === "receiver" ? 1 : 2)};
  }
  .contentMessage {
    background-color: var(--graycard);
    border: 1px solid var(--gray);
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    font-family: "Noto Serif HK", serif;
    order: ${(props) => (props.from === "receiver" ? 2 : 1)};
    height: auto;
    /* max-width: 90%; */

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    svg {
      color: var(--yellow);
      font-size: 18px;
      margin-left: 8px;
    }
  }

  img:not(.profile) {
    height: 180px;
    width: 250px;
  }

  @media (max-width: 890px) {
    .contentMessage {
      max-width: 80%;
    }
  }
  @media (max-width: 721px) {
    .contentMessage {
      max-width: 90%;
    }
  }
  @media (max-width: 420px) {
    img:not(.profile) {
      width: 220px;
    }
  }
  @media (max-width: 385px) {
    img:not(.profile) {
      width: 200px;
    }
  }
  @media (max-width: 360px) {
    img:not(.profile) {
      width: 180px;
    }
  }
  @media (max-width: 345px) {
    img:not(.profile) {
      width: 170px;
    }
  }
`;

export const SectionModal = styled.section`
  width: 100%;

  img {
    max-height: 98%;
    width: 100%;
  }
`;
