import React from "react";
import { Container, Title, Subtitle, DivRow } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import api from "../../services";
import CardCreator from "../CardCreator";
import Button from "../Button/Button";
import { useHistory } from "react-router-dom";

const SectionCreators = () => {
  const [creators, setCreators] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getCreators();
  }, []);

  const getCreators = async () => {
    try {
      const response = await api.get("api/v1/visitas/mais-visitados/");
      if (response) {
        setCreators(response.data);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };
  return (
    <Container>
      <Title>Conecte-se e Colabore com Creators</Title>
      <Subtitle>
        Desbloqueie acesso incomparável a criadores e influenciadores
        excepcionais, encontrando perfeitamente a combinação perfeita para
        ampliar a jornada do Boraver com sua marca.
      </Subtitle>
      <DivRow>
        {/* <Button 
          setBackgroundColor={"var(--white)"} 
          setColor={"var(--black)"} 
          style={{boxShadow: '4px 4px 0 0 var(--black)'}}
          onClick={() => history.push('lista-creators')}
        > */}
          <Subtitle>Mais Populares</Subtitle>
        {/* </Button> */}
      </DivRow>
      <DivRow className="cards">
        {creators.length
          ? creators.map((item, index) => (
              <CardCreator
                creator={item}
                image={item.profile_picture}
                segmento={item.segmentos_order}
              />
            ))
          : null}
      </DivRow>
    </Container>
  );
};

export default SectionCreators;
