import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  textarea {
    padding: 0.8rem;
    font-size: 1rem;
    resize: none;
    width: 100%;
    height: 100px;
    border: 1px solid var(--mediumgray);
    border-radius: 5px;
    text-align: justify;
    margin: 10px 0 20px 0;
  }

  .cancel-button:hover {
    background-color: var(--red);
    color: var(--white);
  }

  @media(max-width: 425px) {
    overflow-y: auto;

    & > div {
      width: 100% !important;
    }

    div > input {
      width: 100% !important;
    }
  }
`
