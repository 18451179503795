import styled from "styled-components";


export const Container = styled.li`
  padding: 24px;
  padding-left: 20px;
  /* cursor: pointer; */
  width: 28rem;
  height: 30%;
  background-color: var(--white);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  h3 {
    text-align: left !important;
    color: ${(props) =>
      props.isActive ? "var(--brightpurple)" : "var(--purple)"};
    font-weight: ${(props) => (props.isActive ? "bolder" : "500")};
    font-size: 28px;
    margin-bottom: 12px;
  }
  p {
    color: ${(props) =>
      props.isActive ? "var(--black)" : "var(--darkgray)"};
    font-weight: ${(props) => (props.isActive ? "500" : "300")};
    font-size: 16px;
    width: 90%;
    margin-bottom: 12px;
  }
  .emptyProgress {
    width: 90%;
    height: 6px;
    background-color: var(--gray);
    border-radius: 5px;
    .progress {
      width: ${(props) => props.progress}%;
      height: 5px;
      background-color: var(--brightpurple);
      border-radius: 5px;
      transition: 0.5s;
    }
  }

  @media (max-width: 1024px) {
    .appItems{
      display: none;
    }
  }
`;