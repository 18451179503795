import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--white-50);
  display: flex;
  margin-left: auto;
  height: 90dvh;
  width: ${(props) => props.visibleSidebar ? '82%' : '100%'};
  padding: 50px 80px;

  /* @media (max-width: 700px) {
    ${props => props.visibleSidebar ? "width: calc(100% - 155px);" : null}
  } */

  @media (max-width: 655px) {
    padding: 50px 30px;
  }

  @media (max-width: 465px) {
    padding: 40px 20px;
  }
  
  /* @media (max-width: 425px) {
    ${props => props.logged ? "width: calc(100% - 135px);" : null}
  } */
`;

export const Main = styled.div`
  width: 100%; //${props => props.visibleSidebar ? "78%" : "100%"};
  /* margin-left: ${props => props.visibleSidebar ? "auto": 0}; */
  /* transition: width 0.7s, margin-left 0.7s; */
`;

export const Content = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  padding: 2rem 0;
  margin-top: 2rem;

  .searchIcon {
    margin-right: 10px;
    color: var(--mediumgray);
  }

  .searchPlace {
    display: flex;
    width: 100%;
    max-width: 90%;
    justify-content: space-around;
  }

  .inputPlace {
    border: 1px solid var(--gray);
    border-radius: 18px;
    padding: 10px 14px 10px 24px;
    display: flex;
    align-items: center;

    input {
      width: 20rem;
      max-width: 90%;
      border: none;
      ::placeholder {
        color: var(--gray);
        font-family: "Poppins", sans-serif;
        font-size: 12px;
      }
    }
  }

  .btnFilter {
    padding: 8px 20px;
    border-radius: 18px;
    border: 1px solid var(--gray);
    background-color: var(--brightpurple);
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 10px;
    min-width: 110px;
    
    button {
      background-color: transparent;
      border: none;
      color: var(--white);
      font-weight: 500;
      font-size: 1rem;
      padding-left: 10px;
      cursor: pointer;

      /* span {
        font-size: 1.2rem;
        font-weight: bold;
      } */
    }
  }

  @media (max-width: 768px) {
    padding: 0 10px;

    .inputPlace {
      padding: 8px 10px;
      max-width: 70%;
    }
  }

  @media (max-width: 655px) {
    justify-content: space-between;
  }

  @media (max-width: 465px) {
    justify-content: center;
    .searchPlace {
      flex-direction: column;
    }
    .inputPlace {
      margin-bottom: 10px;
      max-width: 100%;
    }
  }

  @media (max-width: 425px) {
    padding: 0;
  }

  @media (max-width: 350px) {
    .searchPlace {
      max-width: 100%;
    }
  }

  @media (max-width: 320px) {
    .searchPlace {
      flex-direction: column;
    }

    .inputPlace {
      margin-bottom: 10px;
      max-width: 100%;
    }

    .btnFilter {
      margin-left: 0;
    }
  }
`;

export const InfluencersList = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  //background-color: aquamarine;
`;

export const DivBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 44px;

  @media (max-width: 500px) {
    font-size: 32px;
  }
`;

export const DivImage = styled.div`
  border: 1px solid var(--black);
  border-radius: 50%;
  overflow: hidden;
  height: 60px;
  width: 60px;

  img {
    object-fit: cover;
  }

  @media (max-width: 500px) {
    height: 50px;
    width: 50px;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

export const Card = styled.div`
  background-color: var(--white);
  border: 2px solid var(--graycard);
  border-radius: 5px;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 230px;
  padding: 14px 10px;

  span {
    text-align: end;
  }

  @media (max-width: 655px) {
    width: 45%;
  }

  @media (max-width: 465px) {
    width: 80%;
    min-width: 250px;
  }

  /* @media (max-width: 400px) {
    padding: 8px 4px;
    & > div {
      justify-content: flex-start;
    }
  } */
`;

export const DivColumn = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: var(--gray-50);
    font-family: Inconsolata;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  span {
    font-family: Inconsolata;
    font-size: 24px;
    font-weight: 700;
  }

  @media (max-width: 400px) {
    p {
      font-size: 14px;
    }
    span {
      font-size: 18px;
    }
  }
`;

export const DivIcon = styled.div`
  background-color: var(--brightpurple);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  width: 50px;
  padding: 6px;

  /* @media (max-width: 445px) {
    width: 40px;
  } */
`;