import React from "react";
import { Container, Title, Subtitle, DivVideo } from "./styles";

const SectionVideo = () => {
  return (
    <Container>
      <Title>Processo Simplificado, Impácto Máximo</Title>
      <Subtitle>
        Melhore o seu marketing de conteúdo juntando-se à nossa plataforma e
        conectando-se com os principais creators do mercado
      </Subtitle>
      <Subtitle>
        Crie sua conta | Acesse os diversos creators disponíveis | Filtre pelo
        seu segmento de mercado
      </Subtitle>
      <DivVideo>
        <iframe
          src="https://www.youtube.com/embed/PRsVXXSUxSo"
          title="Plataforma UGC para marcas"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </DivVideo>
    </Container>
  );
};

export default SectionVideo;
