import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
`;

export const Title = styled.h2`
    color: var(--black);
    font-family: Inconsolata;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
`;

export const Subtitle = styled.h3`
    color: var(--black);
    font-family: Archivo;
    font-size: 22px;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 14px;
`;

export const Description = styled.p`
    font-family: Inconsolata;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    width: 90%;
`;

export const DivRow = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const DivColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23%;
`;