import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Header from "../../components/Header/Header"
import Input from "../../components/Input/Input"
import ContactUsPic from '../../assets/ContactUsPic.png'
import { Content, Form, LeftSide, RightSide } from "./styles"
import Button from '../../components/Button/Button'
import api from '../../services'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'

const NewContactUs = () => {

  const history = useHistory()

  const schema = yup.object().shape({
    email: yup.string().email('Email inválido!').required("Campo obrigatório!"),
    titulo: yup.string().required('Campo obrigatório!'),
    mensagem: yup.string().min(5, 'Este campo deve conter pelo menos 5 caracteres').required("Campo obrigatório!")
  })

  const {register, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)})

  const newSuggestion = (newSuggestion) => {
    let suggestion = newSuggestion
    suggestion.tipo_usuario = "cliente"
    suggestion.nomeusuario = ""
    api
        .post("/api/v1/faleconosco/", suggestion)
        .then((res) => {
          toast.success("Mensagem enviada com sucesso!")
          return history.push('/') 
        })
  }

  return(
    <div>
      {/* <Header /> */}
      <Content>
        <LeftSide>
          <img src={ContactUsPic} alt="logo" />
        </LeftSide>
        <RightSide>
          <Form onSubmit={handleSubmit(newSuggestion)}>
            <h2>Fale conosco</h2>
            <p>Tire suas dúvidas ou compartilhe sua experiência em nosso site</p>
            <Input
              register={register}
              name="email"
              label="Email"
              placeholder="Digite seu email"
              error={errors.email?.message}
            />
            <Input
              register={register}
              name="titulo"
              label="Assunto"
              placeholder="Digite o assunto"
              error={errors.subject?.message}
            />
            <Input
              register={register}
              name="mensagem"
              label="Sugestão"
              placeholder="Digite sua sugestão"
              error={errors.suggestion?.message}
            />
            <Button
              setBackgroundColor="var(--purple)"
              setColor="var(--white)"
              large
              type="submit"
            >
              Enviar
            </Button>
          </Form>
        </RightSide>
      </Content>
      {/* <Footer /> */}
    </div>
  )
}
export default NewContactUs