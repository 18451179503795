import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  padding: 70px 10px;
  width: 90%;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  img {
    mix-blend-mode: multiply;
    width: 32%;
  }
  &>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 68%;
  }

  button {
    margin-top: 30px;
    padding: 18px 12px;
    height: 60px;
    width: 250px;
  }

  button:hover {
    background-color: #dfa601;
    box-shadow: 1px 3px 15px 1px #dfa601;
  }

  @media (max-width: 1100px) {
    .imageHero {
      height: 300px;
      width: 28%;
    }
    button {
      width: 220px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;

    .imageHero {
      margin-inline: auto;
      margin-bottom: 10px;
      width: 32%
    }

    div {
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    .imageHero {
      width: 40%;
      min-width: 260px;
    }
  }

  @media (max-width: 563px) {
    button:nth-of-type(2) {
      padding: ${(props) => (props.large ? "8px 48px" : "8px 6px")};
    }
  }
  
  @media (max-width: 450px) {
    button:nth-of-type(1) {
      padding: ${(props) => (props.large ? "8px 48px" : "8px 6px")};
    }
  }
`;

export const Title = styled.h1`
  color: var(--black);
  font-family: Inconsolata;
  font-size: 60px;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;

  @media (max-width: 1100px) {
    font-size: 46px;
  }

  @media (max-width: 600px) {
    font-size: 38px;
    /* line-height: 40px; */
  }
`;

export const Subtitle = styled.p`
  color: var(--black);
  font-family: Insoncolata;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: center;
  width: 94%;
`;
