import { toast } from "react-toastify";
import Button from "../Button/Button";
import { Container } from "./styles";

export function RegisterNewService({ 
  valorServico, 
  servico,
  descricaoServico, 
  setServico, 
  setValorServico,
  setDescricaoServico, 
  setPacotes, 
  setShowNewService, 
  pacotes
}) {
  return (
    <Container>
      <div>
        <h3>Serviço</h3>
        <p>Digite o serviço desejado</p>
        {/* <input type="text" onChange={({ target }) => handleValue("nome", target.value)} /> */}
        <input type="text" onChange={({ target }) => setServico(target.value)} />
      </div>
      <div>
        <h3>Descrição</h3>
        <p>Digite a descrição do serviço desejado</p>
        {/* <input type="text" onChange={({ target }) => handleValue("valor", target.value)} /> */}
        <textarea 
          value={descricaoServico}
          onChange={({ target }) => setDescricaoServico(target.value)}
        />
      </div>
      <div>
        <h3>Valor</h3>
        <p>Digite o valor desejado</p>
        {/* <input type="text" onChange={({ target }) => handleValue("valor", target.value)} /> */}
        <input type="number" onChange={({ target }) => setValorServico(target.value) }/>
      </div>
      
      <div className="buttons-container">
        <Button
          setBackgroundColor="var(--purple)"
          setColor="var(--white)"
          type="button"
          onClick={() => { 
            if (valorServico !== '0' && valorServico != '' && servico !== '') {
              setPacotes([ ...pacotes, { nome: servico, descricao: descricaoServico, valor: valorServico }]);
              setShowNewService(false);
            } else if (valorServico === '0' || valorServico === '') {
              toast.error('O valor do serviço precisa ser preenchido')
            } else if (servico === '') {
              toast.error('O serviço precisa ser preenchido')
            }
          }}>
          Confirmar
        </Button>

        <Button
          className="cancel-button"
          setBackgroundColor="var(--white)"
          setColor="var(--red)"
          type="button"
          onClick={() => { 
            setShowNewService(false);
          }}>
          Cancelar
        </Button>
      </div>
    </Container>
  );
}