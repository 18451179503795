import api from "../../services/index";

export const getInfluencer = async (id, token) => {
  try {
    const response = await api.get(`/api/v1/influencers/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw Error("Erro ao buscar influenciador.");
  }
};

export const getInfluencePacotes = async (idInfluencer, token) => {
  try {
    const response = await api.get(`/api/v1/pacotes/`, {
      params: {
        influenceId: idInfluencer,
      },
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


