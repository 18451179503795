import './styles.css';

export default function Spinner({ positioned }) {
    if (positioned) {
        return (
            <div className="spinner">
                <span 
                    className="loader"
                    style={{
                        position: 'absolute',
                        right: '18px',
                        bottom: '0px'
                    }}
                >
                </span>
            </div>
        );
    }
    
    return (
        <div className="spinner">
            <span className="loader">
            </span>
        </div> 
    );
}



