import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  width: 100%;
`;

export const Card = styled.div`
  border: 3px solid var(--black);
  background-color: var(--white);
  box-shadow: 5px 5px 0 0 var(--black);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
  height: auto;
  width: 500px;
  padding: 30px 20px;

  label {
    display: flex;
    font-family: Inconsolata;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 8px;
    width: 100%;
  }

  input {
    border: 2px solid var(--black);
    border-radius: 5px;
    margin-bottom: 6px;
    padding: 18px 12px;
    width: 100%;
  }

  span {
    font-size: 14px;
  }

  .logo {
    background-color: var(--gray);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;

    span {
      font-family: Inconsolata;
      font-size: 14px;
      text-align: center;
    }
  }

  @media (max-width: 550px) {
    width: 95%;
  }
`;

export const Title = styled.h2`
  color: var(--black);
  font-size: Inconsolata;
  font-size: 28px;
  font-weight: 700;
`;

export const DescriptionStep = styled.p`
    font-family: Inconsolata;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
`;

export const DivOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 100%;
`;

export const Option = styled.div`
  background-color: ${(props) =>
    props.active ? "var(--purple-50)" : "var(--lightPurple)"};
  color: ${(props) => (props.active ? "var(--white)" : "var(--black)")};
  cursor: pointer;
  border: 2px solid var(--black);
  border-radius: 5px;
  display: flex;
  justify-content: ${props => props.active ? "space-between" : "first-start"};
  font-family: Inconsolata;
  font-size: 22px;
  font-weight: 500;
  padding: 18px 12px;
`;

export const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ButtonOption = styled(Option)`
  font-size: 18px;
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 8px 12px;
  width: fit-content;
`;

export const DivCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    border: 1px solid var(--black);
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
`;

export const SelectCustom = styled.select`
  background-color: var(--white);
  border: 2px solid var(--black);
  border-radius: 5px;
  margin-bottom: 6px;
  width: 100%;
  padding: 18px 12px;
`;