import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding-top: 20px; */
  z-index: 1;
  min-width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  left: ${(props) => (props.isMenu ? 0 : "")};
  background-color: rgba(0, 0, 0, 0.7);
  @media (max-width: 500px) {
    min-width: 100%;
    height: 115vh;
    top: 0;
    position: absolute;
  }
`;

export const Content = styled.form`
  width: ${(props) => (props.isMenu ? "375px" : "60%")};
  /* height: ${(props) => (props.isMenu ? "100%" : "auto")}; */
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: fixed;
  top: 30px;
  bottom: 30px;
  /* height: 100%; */
  left: ${(props) => (props.isMenu ? "0" : "")};
  border-radius: 8px;

  .headerModal {
    display: flex;
    justify-content: ${(props) =>
      props.isMenu ? "space-around" : "space-between"};
    align-items: center;
    padding: ${props => props.isMenu ? "10px 0" : "20px 0"};
    border-bottom: 1px solid var(--softgray);
    box-shadow: 0px 2px 10px 0px var(--gray);
    background-color: ${(props) => (props.isMenu ? "var(--purple)" : "")};
    height: ${props => props.isMenu ? "100px" : "70px"};

    .logoDiv {
      overflow-y: hidden;
    }

    img {
      max-width: 80px;
      min-width: 50px;
      height: 40px;
      @media (max-width: 500px) {
        max-width: 80px;
      }
    }

    h2 {
      text-align: center;
      color: var(--black);
      font-size: 18px;
      margin-left: 20px;
    }
    button {
      border: none;
      background-color: ${(props) =>
        props.isMenu ? "var(--purple)" : "var(--white)"};
      font-size: 20px;
      font-weight: 600;
      margin-right: 20px;
      color: var(--mediumgray);
      cursor: pointer;
      :hover {
        color: var(--black);
      }
    }

    @media (max-width: 390px) {
      padding: 0;
    }
  }

  .contentModal {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.isMenu ? "flex-start" : "center")};
    align-items: center;
    padding: 10px 16px 20px;
    height: ${(props) => (props.isMenu ? "100%" : "")};
    ul {
      height: 60%;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      li {
        margin: 18px 0;
        color: var(--purple);
        font-weight: 600;
        font-size: 28px;
        cursor: pointer;
        @media (max-width: 500px) {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
    .pixModalText {
      color: var(--black);
      font-size: 16px;
      margin: 10px 0;
      font-weight: 500;
      text-align: center;
    }
    .pixModalTextBold {
      margin: 20px 0;
      text-align: center;
    }
    .pixModalTelNumber {
      margin-bottom: 20px;
      font-size: 20px;
    }
    .pixModalTextSpan {
      color: var(--black);
      font-size: 14px;
      margin: 10px 0 20px 0;
      font-weight: 500;
      text-align: center;
    }
    .cardInputDiv {
      display: flex;
      align-items: center;
      margin: 10px 0;
      /* background-color: blue; */
      input {
        margin: 0;
      }
      h3 {
        margin-right: 20px;
        width: 30%;
        /* background-color: blue; */
      }
      .expirationDate {
        display: flex;
        width: 70%;
        select {
          padding: 6px 15px;
          margin: 0 10px;
          border-radius: 15px;
          border: 1px solid var(--mediumgray);
        }
      }
    }
    .footerModal {
      padding: 20px 0 10px 0;
      display: flex;
      justify-content: flex-end;
      button {
        border: none;
        padding: 8px 15px;
        font-weight: 500;
        border-radius: 10px;
        cursor: pointer;
      }
      .gray {
        background-color: var(--mediumgray);
        :hover {
          background-color: var(--darkgray);
          color: var(--white);
        }
      }
      .purple {
        background-color: var(--brightpurple);
        color: var(--white);
        :hover {
          background-color: var(--blue);
        }
      }
    }
    div {
      width: 100%;
      h3 {
        color: var(--black);
        font-size: 18px;
      }
      p {
        color: var(--mediumgray);
        font-size: 12px;
      }
      label {
        display: flex;
        font-family: Inconsolata;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 8px;
        width: 100%;
      }

      input {
        border: 2px solid var(--black);
        border-radius: 5px;
        margin-bottom: 6px;
        padding: 12px;
        width: 100%;
      }
      select {
        width: 100%;
        border: 2px solid var(--black);
        border-radius: 5px;
        padding: 8px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      textarea {
        margin: 10px 0 20px 0;
        border: 2px solid var(--black);
        border-radius: 5px;
        padding: 5px 10px;
        height: 80px;
        width: 90%;
      }
    }
  }

  textarea {
    min-height: 70px;
    min-width: 350px;
    max-height: 100px;
    /* max-width: 400px; */
  }

  .socialMediaFilter {
    margin: 10px 0px;
    h3 {
      /* cursor: pointer; */
      margin-bottom: 10px;
    }
  }

  .regionFilter {
    h3 {
      cursor: pointer;
      margin-top: 10px;
    }
    .inputPlace {
      .searchIcon {
        position: absolute;
        top: 130px;
        left: 50px;
        @media (max-width: 1180px) {
          left: 40px;
        }
      }
      input {
        padding-left: 40px;
        margin-right: 20px;
        margin-top: 20px;
      }
    }
  }

  .segmentFilter {
    margin-top: 20px;

    h3 {
      /* cursor: pointer; */
      margin-bottom: 10px;
    }
    .inputPlace {
      & > .multiSelect {
        min-height: max-content !important;
        overflow: visible !important;

        & > div:nth-of-type(1) > div:nth-of-type(2) {
          width: auto !important;
        }

        & > label {
          line-height: 12px;
          margin-top: 6px;
          margin-bottom: 0;
        }

        & > span {
          color: var(--purple);
          font-size: 12px;
          margin-top: 0;
          margin-bottom: 8px;
          text-align: left;
        }

        & > div {
          overflow: visible;
          font-size: 14px;

          .css-1jqq78o-placeholder {
            color: var(--black);
          }

          .css-1u9des2-indicatorSeparator {
            display: none;
          }

          .css-1xc3v61-indicatorContainer {
            color: var(--black);
            padding: 8px 4px;
          }

          #react-select-3-listbox {
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
            border: 1px solid #ccc;
            left: 2px;
            right: 2px;
            width: 96%;
            z-index: 9999;
          }
        }
      }
      .searchIcon {
        position: absolute;
        top: 120px;
        left: 50px;
        @media (max-width: 1180px) {
          left: 40px;
        }
      }
      input {
        padding-left: 40px;
        margin-right: 20px;
        margin-top: 20px;
      }
    }
  }

  .followersFilter {
    h3 {
      cursor: pointer;
      margin: 10px 0;
    }
    h3:hover {
      color: var(--brightpurple);
    }
    span {
      text-align: center;
    }

    .followersFilterInputDiv {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      /* background-color: blue; */

      .followersFilterInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 40%;
        margin: 20px 0 5px 0;
        /* background-color: fuchsia; */

        input {
          width: 100%;
          margin-bottom: 35px;
        }

        label {
          text-align: left;
          align-self: flex-start;
          margin-left: 5px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    width: 80%;
  }

  @media (max-width: 450px) {
    min-width: 90%;
    .contentModal > div > textarea {
      min-width: 100%;
    }
  }
`;
