import { useEffect, useRef, useState } from "react";
import Banner from "../../components/Banner/Banner"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import HorizontalSection from "../../components/HorizontalSection/HorizontalSection"
import Testimonial from "../../components/Testimonial/Testimonial"
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import { Mock } from "./Home.mock";
import { StepBox } from "./styles";
import StepOne from "../../assets/ContactUsPic.png"
import StepTwo from "../../assets/LoginPic.png"
import StepThree from "../../assets/SignupPic.png"
import Faq from "../../components/Faq/Faq";
import SectionInspired from "../../components/section-inspired";
import SectionVideo from "../../components/SectionVideo";
import SectionCompanys from "../../components/SectionCompanys";
import SectionHero from "../../components/SectionHero";
import SectionCreators from "../../components/SectionCreators";

const Home = () => {

  const childRef = useRef(null);

  const [progress, setProgress] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);
  const [image, setImage] = useState(StepOne)

  const renderItems = () => {
    return Mock.map(({ title, subtitle }, index) => {
      return (
        <HowItWorks
          key={index}
          title={title}
          subtitle={subtitle}
          progress={selectedItem === index ? progress : 0}
          isActive={selectedItem === index ? true : false}
          index={index}
          ref={childRef}
        />
      );
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (childRef.current?.isPausing) return;

      setProgress(progress + 1);
      if (progress > 99) {
        let nextItem;
        if(selectedItem === 0){
          nextItem = 1
          setImage(StepTwo)
        }
        if(selectedItem === 1){
          nextItem = 2
          setImage(StepThree)
        }
        if(selectedItem === 2){
          nextItem = 0
          setImage(StepOne)
        }
        setSelectedItem(nextItem);

        setProgress(0);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [progress]);

  return(
    <div>
      {/* <Header isHome /> */}
      {/* <Banner /> */}
      {/* <StepBox>
      <h3>Passo a Passo BoraVer UGC</h3>
        <div className="section">
          <ul className="appItems">{renderItems()}</ul>
          <img src={image} alt="" />
        </div>
      </StepBox>
      <Testimonial />
      <HorizontalSection /> */}
      <SectionHero />
      <SectionCompanys />
      <SectionVideo />
      <SectionCreators />
      <SectionInspired />
      <Faq />
      {/* <Footer isContactUs={false} /> */}
    </div>
  )
}
export default Home