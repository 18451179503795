import api from "../index"

export const PackageClient = async (id, token) => {
    try {
        const response = await api.get("/api/v1/pacotes/", {
            params: {
                clientId: id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const CreatePackage = async (payload, token) => {
    try {
        const response = await api.post("api/v1/pacotes/", payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const EditPackage = async (id, payload, token) => {
    try {
        const response = await api.patch(`api/v1/pacotes/${id}/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const DeletePackage = async (id, token) => {
    try {
        const response = await api.delete(`api/v1/pacotes/${id}/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })

        return response;
    } catch (error) {
        throw Error(error);
    }
}

export const CampanhaClient = async (id, token, search) => {
    try {
        const response = await api.get("/api/v1/campanhas/", {
            params: {
                id_cliente: id,
                nome_campanha: search,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const CreateCampaign = async (payload, token) => {
    try {
        const response = await api.post("api/v1/campanhas/", payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const EditCampaign = async (id, payload, token) => {
    try {
        const response = await api.patch(`api/v1/campanhas/${id}/`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}

export const DeleteCampaign = async (id, token) => {
    try {
        const response = await api.delete(`api/v1/campanhas/${id}/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })

        return response;
    } catch (error) {
        throw Error(error);
    }
}

export const FaixaEtaria = async (token) => {
    try {
        const response = await api.get("/api/v1/faixa-etaria/", {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        throw Error(error);
    }
}