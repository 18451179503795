import styled from "styled-components";

export const Container = styled.div`
  background-color: #dec9e9;
  border-radius: 10px;
  cursor: pointer;
  height: auto;
  min-height: 300px;
  margin: 2rem;
  max-width: 250px;
  width: 250px;

  :hover {
    box-shadow: 5px 5px 9px 2px rgba(205, 205, 205, 0.75);
  }

  img {
    border-radius: 10px 10px 0px 0px;
    height: 180px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 300px) {
    margin: 1rem;
  }
`;

export const Content = styled.div`
  text-align: center;
  margin-top: 10px;

  h4,
  p {
    color: #3c2e54;
    margin: 0 10px;
    word-wrap: break-word;
  }
`;
