import styled from "styled-components";
import Logo from "../../assets/Logotipo_boraver_ComFundo.jpg";

export const Container = styled.div`
    height: 80dvh;
    position: relative;
`;

export const Background = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    z-index: -1;
    
    &::before {
        content: "";
        position: fixed;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        opacity: 0.07;
        filter: blur(50px);
        height: 100%;
        width: 100%;
    }
    
    &::after {
        content: "";
        position: fixed;
        background-image: url(${Logo});
        background-repeat: repeat;
        background-size: cover;
        background-position: center center;
        opacity: 0.05;
        mix-blend-mode: color;
        height: 100%;
        width: 100%;
    }
`;

export const Main = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    border-radius: 50%;
    height: 120px;
    margin-top: 50px;
    width: 120px;
`;

export const Title = styled.h1`
    color: var(--black);
    font-size: 22px;
    font-weight: 800;
    margin-top: 20px;
`;

export const Subtitle = styled.h2`
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;
    width: 30%;
    min-width: 430px;
`;

export const DivCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
    width: 100%;
    z-index: 2;
`;

export const Card = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;
    margin-inline: auto;
    padding: 10px;
    width: 480px;
    transition: all 0.3s;
    
    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: var(--black);
        width: 75%;
        text-decoration: none;
    }

    p {
        font-weight: 600;
        text-align: center;
        width: 90%;
    }

    svg {
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.02);
    }

    @media (max-width: 500px) {
        width: 90%;
        a {
            width: 80%;
        }
    }

    @media (max-width: 400px) {
        a {
            width: 85%;
        }
        p {
            font-size: 14px;
        }
    }
`;