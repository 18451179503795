import styled from "styled-components";

export const ButtonContainer = styled.button`
  color: var(--purple);
  cursor: pointer;
  border: 0;
  background: transparent;
  margin-left: 3px;
`

