import { Container, Describe, InputContainer, MaskedInput } from "./styles"

const Input = ({label, large, register, name, error="", isContactUs, mask, describe, ...rest}) => {

  return(
    <Container>
      <div>
        {label} {!!error && <span> - {error}</span>}
      </div>
      <InputContainer isErrored={!!error} isContactUs={isContactUs}>
        {mask ?
          <MaskedInput mask={mask} {...register(name)} {...rest}/> 
          : 
          <input {...register(name)} {...rest} />
        }
      </InputContainer>
      {describe ? (
        <Describe>{describe}</Describe>
      ) : null}
    </Container>
  )
}
export default Input