import React, { useEffect, useRef, useState } from "react";
import { Container, DivRow, DivCenter, Subtitle, Title } from "./styles";
import InputSearch from "../../components/InputSearch";
import api from "../../services";
import CardCreator from "../../components/CardCreator";
import Button from "../../components/Button/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import SectionCompanys from "../../components/SectionCompanys";
import SectionHowWork from "../../components/SectionHowWork";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../providers/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ListCreators = () => {
  const { auth } = useAuth();
  const history = useHistory();
  const totalPages = useRef();
  const page = useRef(1);
  const [searchValue, setSearchValue] = useState("");
  const [influencers, setInfluencers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    if (searchValue === "")
      getInfluencers();
  }, [searchValue])

  const getInfluencers = async (search, _page = 1) => {
    try {
        setLoading(true);

        let _params = {
          page: _page,
          ativo: true,
          search: search
        }

        const response = await api.get('api/v1/influencers/list-influencers/', {
            params: _params
        })

        if (response) {
          const total =
              response.data.count % 24 === 0
                ? response.data.count / 24
                : Math.floor(response.data.count / 24) + 1;
            totalPages.current = total;

            setInfluencers(response.data.results);
            page.current = _page;
            setLoading(false);
          
          window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        }
    } catch (error) {
        console.error("Erro: ", error);
    } finally {
      setLoading(false);
    }
  }

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const handlePagination = (_page) => {
    if (_page < 1 || _page > totalPages.current) return;

    // if (!auth) {
    //   history.push('/login')
    // }

    getInfluencers(null, _page);
  }

  return (
    <Container>
      <Title>Procure o Melhor Creator Para Você</Title>
      <Subtitle>
        Explore perfis, veja portfólios e faça propostas diretamente na nossa
        plataforma. Simplifique sua busca pelo criador perfeito!
      </Subtitle>
      <DivCenter>
        <InputSearch
          name="search"
          value={searchValue}
          placeholder="Buscar Creator..."
          // handleChange={handleSearchChange}
          submit={getInfluencers}
        />
      </DivCenter>
      <DivRow>
        {influencers.length ? influencers.map((item) => (
          <CardCreator 
            creator={item}
            image={item.profile_picture}
            segmento={item.segmentos_order}
          />
        )) : null}
      </DivRow>
      <DivCenter style={{marginBottom: 10}}>
        <Button
          setBackgroundColor={'var(--white)'}
          setColor={'var(--back)'}
          disabled={loading || page.current === 1}
          onClick={() => handlePagination(page.current - 1)}
          >
          <FaArrowLeft size={10}  />{" "}
          Anterior
        </Button>
        <Button
          setBackgroundColor={'var(--white)'}
          setColor={'var(--back)'}
          disabled={loading || page.current === totalPages.current}
          onClick={() => handlePagination(page.current + 1)}
        >
          Próximo{" "}
          <FaArrowRight size={10}  />
        </Button>
      </DivCenter>
      {loading ? (
        <Spinner />
      ) : (
        <div style={{height: 80}}></div>
      )}
      <br />
      <SectionHowWork />
      <SectionCompanys />
    </Container>
  );
};

export default ListCreators;
