import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AliciaPic from "../../assets/user.webp";
import {
  Container,
  Content,
  Details,
  DivColumn,
  Highlights,
  ImageFeed,
  ImageStorie,
  Informations,
  ItemPacote,
  PacotesList,
  Total,
} from "./styles";
import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import { useHistory, Link } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { toast } from "react-toastify";
import { postSolicitation } from "../../providers/solicitation";
import { useClient } from "../../providers/user";
import { getInfluencePacotes } from "../../providers/influencer";
import { toReal } from "../../utils/currency";
import { formatFollowers, onlyNumber, resumeSolicitation } from "../../utils/helpers";
import { TextButton } from "../../components/TextButton";
import { RegisterNewService } from "../../components/RegisterNewService";
import { ServiceModal } from "../../components/ServiceModal";
import Hand from "../../assets/hand.png";
import api from "../../services/index";
import InputMask from "react-input-mask";
import { regioes } from "../../utils/helpers";
import { GetFotoCapa } from "../../services/fotosCapa";
import { FaInstagram, FaTiktok } from "react-icons/fa";

const InfluencerDetails = () => {
  const { state } = useLocation();
  const { auth } = useAuth();
  const { clientId } = useClient();
  const history = useHistory();

  const [influencerData, setInfluencerData] = useState();
  const [influencerId, setInfluencerId] = useState();
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const [shouldOpenModalVisitor, setShouldOpenModalVisitor] = useState(false);
  const [shouldOpenModalConfirm, setShouldOpenModalConfirm] = useState(false);
  const [payload, setPayload] = useState({
    titulo: "Solicitar creator",
    cliente: "",
    influencidor: "",
    nome_empresa: "",
    email_cliente: "",
    celular_cliente: "",
    valorads: 20,
    periodo: "",
    inicio: "",
    valor: "",
    qtddias: 90,
    descricao_servico: "",
    status: "Nova Oferta",
    segmento: "",
    exclusividade: false,
    solicitante: 0, // 0 quando o cliente for o solicitante e 1 quando o influencer for o solicitante
  });
  const [pacotes, setPacotes] = useState([]);
  const [choicePacotes, setChoicesPacotes] = useState([]);
  const [servico, setServico] = useState("");
  const [descricaoServico, setDescricaoServico] = useState("");
  const [valorServico, setValorServico] = useState("");
  const [showNewService, setShowNewService] = useState(false);
  const [segmentos, setSegmentos] = useState([]);
  const [idSegmento, setIdSegmento] = useState();
  const [exclusividade, setExclusividade] = useState(false);
  const [personalizar, setPersonalizar] = useState(false);
  const [acenado, setAcenado] = useState(false);
  const [visitor, setVisitor] = useState(false);
  const [uf, setUf] = useState([]);
  const [citySelected, setCitySelected] = useState();
  const [city, setCity] = useState([]);
  const [stateSelected, setStateSelected] = useState();
  const [instaInfluencer, setInstaInfluencer] = useState();
  const [tiktokInfluencer, setTiktokInfluencer] = useState();
  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [inicio, setInicio] = useState([
    "Imediato",
    "Em 2 dias",
    "Nesta semana",
    "Personalizar"
  ]);
  const periodos = [
    {
      id: 1,
      qtdDias: "7 dias",
    },

    {
      id: 2,
      qtdDias: "15 dias",
    },

    {
      id: 3,
      qtdDias: "30 dias",
    },

    {
      id: 4,
      qtdDias: "60 dias",
    },

    {
      id: 5,
      qtdDias: "90 dias",
    },

    {
      id: 6,
      qtdDias: "Personalizar",
    },
  ];

  useEffect(() => {
    const url = window.location.href;
    const partesDaURL = url.split("/");

    const idInfluencer = partesDaURL[partesDaURL.length - 1];

    if (idInfluencer) {
      setInfluencerId(idInfluencer)
      setPayload({
        ...payload,
        influencidor: idInfluencer,
      });
    }
    if (!auth) {
      if (idInfluencer) {
        setVisitor(true);
      } else {
        return history.push("/");
      }
      // visitInfluencer();
    } else {
      loadInfluencePacotes();
    }
  }, []);

  useEffect(() => {
    if (influencerId) {
      getInfluencer(influencerId);
      // getFotoCapa(influencerId);
    }
  }, [influencerId]);

  useEffect(() => {
    if (influencerId) {
      loadInfluencePacotes(influencerId);
    }
  }, [influencerId]);

  useEffect(() => {
    if (visitor && influencerId) {
      visitInfluencer(influencerId, null);
    }
  }, [visitor, influencerId]);

  useEffect(() => {
    if (state) {
      setInfluencerData(state);

      const insta = state.instagram && state.instagram[0] === "@" ? state.instagram.slice(1) : state.instagram;
      const tiktok = state.tiktok && state.tiktok[0] === "@" ? state.tiktok.slice(1) : state.tiktok;

      setInstaInfluencer(insta);
      setTiktokInfluencer(tiktok);
    }
    if (state && clientId) {
      const { id } = state;

      setPayload({
        ...payload,
        cliente: clientId,
        segmento: idSegmento !== 0 ? idSegmento : "",
      });
      setInfluencerId(id);

      checkAceno(id, clientId);
      visitInfluencer(id, clientId);
    }
  }, [state, clientId]);

  useEffect(() => {
    const storedInfluencer = sessionStorage.getItem('influencer');
    if (storedInfluencer) {
      let _influencer = JSON.parse(storedInfluencer)
      setInfluencerData(_influencer);

      const insta = _influencer.instagram && _influencer.instagram[0] === "@" ? _influencer.instagram.slice(1) : _influencer.instagram;
      const tiktok = _influencer.tiktok && _influencer.tiktok[0] === "@" ? _influencer.tiktok.slice(1) : _influencer.tiktok;

      setInstaInfluencer(insta);
      setTiktokInfluencer(tiktok);
      setInfluencerId(_influencer.id);
    }
  }, []);

  useEffect(() => {
    if (clientId && influencerId) {
      setPayload({
        ...payload,
        cliente: clientId,
        segmento: idSegmento !== 0 ? idSegmento : "",
      });

      checkAceno(influencerId, clientId);
      visitInfluencer(influencerId, clientId);
    }
  }, [clientId, influencerId]);


  useEffect(() => {
    if (shouldOpenModal) {
      setPersonalizar(false);
      setPayload({
        ...payload,
        segmento: "",
        periodo: "",
        exclusividade: false,
      });
    } else {
      setChoicesPacotes([])
    }
  }, [shouldOpenModal]);

  useEffect(() => {
    if (shouldOpenModalConfirm) {
      showStates();
    } else {
      setPayload({
        ...payload,
        nome_empresa: "",
        email_cliente: "",
        celular_cliente: "",
        estado: "",
        cidade: "",
      });
    }
  }, [shouldOpenModalConfirm]);

  const getInfluencer = async (id) => {
    const response = await api
      .get(`/api/v1/influencers/`, {
        params: { id: id },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const results = response.data.results[0];

        const insta = results.instagram && results.instagram[0] === "@" ? results.instagram.slice(1) : results.instagram;
        const tiktok = results.tiktok && results.tiktok[0] === "@" ? results.tiktok.slice(1) : results.tiktok;
        setInfluencerData(results);
        setInstaInfluencer(insta);
        setTiktokInfluencer(tiktok);
      });
  };

  const visitInfluencer = async (id, clientId) => {
    const payload = {
      influencer: id,
      cliente: clientId,
    };
    try {
      const response = await api.post("/api/v1/visitas/", payload, {
        headers: {
          Authorization: auth ? `Bearer ${auth}` : null,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadInfluencePacotes = useCallback(async (idInfluencer) => {
    if (state) {
      const { id } = state;
      const response = await getInfluencePacotes(id, auth);

      if (response) {
        setPacotes(response);
      }
    } else if (idInfluencer) {
      const response = await getInfluencePacotes(idInfluencer);

      if (response) {
        setPacotes(response);
      }
    }
  }, []);

  const getFotoCapa = async (id) => {
    try {
      if (id) {
        setLoadingImages(true);
        const result = await GetFotoCapa(id, auth);
        if (result) {
          const { results } = result;
          const sortImage = results.sort(function (a, b) {
            if (a.ordem > b.ordem) {
              return 1;
            }
            if (a.ordem < b.ordem) {
              return -1;
            }
            return 0;
          });

          setImages(sortImage);
        }
      }
    } catch (error) {
      console.error('Erro: ', error);
    } finally {
      setLoadingImages(false);
    }
  }

  const handleChoice = useCallback(
    (item) => {
      const index = isChoiced(item.id);
      if (index > -1) {
        const _newList = [...choicePacotes];
        _newList.splice(index, 1);
        setChoicesPacotes(_newList);
      } else {
        setChoicesPacotes((prev) => [...prev, item]);
      }
    },
    [choicePacotes]
  );

  const isChoiced = useCallback(
    (itemId) => {
      return choicePacotes.findIndex((_item) => _item.id === itemId);
    },
    [choicePacotes]
  );

  const sumChoice = useCallback(() => {
    return choicePacotes.reduce((acc, item) => acc + Number(item.valor), 0);
  }, [choicePacotes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const _descricao = choicePacotes[0].descricao;
      payload.titulo = choicePacotes[0].nome;
      const { exclusividade } = payload;
      const _exclusividade = exclusividade ? sumChoice() * (20 / 100) : 0;

      try {
        const result = await postSolicitation(
          {
            ...payload,
            cliente: clientId ? +clientId : null,
            influencidor: +influencerId,
            valor: sumChoice(),
            valor_exclusividade: _exclusividade,
            descricao_servico: _descricao,
            estado: stateSelected,
            cidade: citySelected,
            tipo_solicitacao: visitor ? 1 : 0, // 0 para solicitação interna e 1 para solicitação externa
          },
          auth
        );
        if (result) {
          if (visitor) {
            toast.success(
              "Sua proposta já chegou para o influenciador, em alguns instantes chegará a resposta no seu Email!"
            );
            setShouldOpenModalConfirm(false);
          } else {
            toast.success("Solicitação enviada com sucesso!");
            setShouldOpenModal(false);
          }
        }
      } catch (error) {
        // console.error("Erro: ", error.response.data);
        console.error(error);
        toast.error(`${error.message}`);
      }
    }
  };

  const handleValue = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleIdSegment = (id) => {
    setPayload({
      ...payload,
      segmento: +id !== 0 ? +id : "",
    });
  };

  const handleExclusividade = () => {
    setExclusividade(!exclusividade);
    setPayload({
      ...payload,
      exclusividade: !exclusividade,
    });
  };

  const validate = () => {
    if (choicePacotes.length < 1) {
      toast.error("Escolha pelo menos 1 serviço");
      return false;
    }
    if (payload.inicio === "") {
      toast.error("O Campo início de divulgação precisa ser preenchido.");
      return false;
    }
    if (payload.segmento === "") {
      toast.error("O Campo seguimento precisa ser preenchido.");
      return false;
    }
    if (visitor && payload.nome_empresa === "") {
      toast.error("O campo nome da empresa precisa ser preenchido.");
      return false;
    }
    if (visitor && payload.email_cliente === "") {
      toast.error("O campo email precisa ser preenchido.");
      return false;
    } else if (
      visitor &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload.email_cliente)
    ) {
      toast.error("Este endereço de e-mail é inválido", "error");
      return false;
    }
    if (visitor && payload.celular_cliente === "") {
      toast.error("O campo contato precisa ser preenchido.");
      return false;
    }
    if (visitor && !stateSelected) {
      toast.error("O campo estado precisa ser preenchido.");
      return false;
    }
    if (visitor && !citySelected) {
      toast.error("O campo cidade precisa ser preenchido.");
      return false;
    }
    return true;
  };

  const checkAceno = async (idInfluencer, idCliente) => {
    try {
      const response = await api.get("/api/v1/acenos/", {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
        params: {
          influencer: idInfluencer,
          cliente: idCliente,
        },
      });

      if (response.data.results.length > 0) {
        setAcenado(true);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const acenar = async () => {
    const payload = {
      influencer: influencerData.id,
      cliente: clientId,
      solicitante: 0, // 0 quando o cliente for o solicitante e 1 quando o influencer for o solicitante
    };
    try {
      const response = await api.post("/api/v1/acenos/", payload, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (response.status === 201) {
        setAcenado(true);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  const calculaPorcentagem = (value) => {
    const total = value + value * (20 / 100);
    return total;
  };

  const handleSolicitation = (item) => {
    if (auth && !visitor) {
      handleChoice(item)
      setShouldOpenModal(true);
    } else if (!auth && visitor) {
      setShouldOpenModalVisitor(true);
    } else {
      history.push("/login");
    }
  };

  function confirmSolicitationVisitor() {
    setShouldOpenModalVisitor(false);
    setShouldOpenModalConfirm(true);
  }

  const showStates = () => {
    let ufs = [];
    regioes.regioes.map((item) => ufs.push(...item.estados));
    setUf(ufs);
  };

  const filterState = (_estado) => {
    setStateSelected(_estado);
    setCity(uf.find((e) => e.nome === _estado).cidades);
  };

  const handleCitySelected = (city) => {
    setCitySelected(city);
  };

  const handlePayloadVisitor = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const checkoutInstagram = async (type) => {
    try {
      let url = '';

      let params = {
        influencer: influencerId,
      }

      if (type === "INSTAGRAM") {
        url = `https://www.instagram.com/${instaInfluencer}/`
        params['tipo_notificacao'] = 7
      } else if (type === 'TIKTOK') {
        url = `https://www.tiktok.com/@${tiktokInfluencer}`
        params['tipo_notificacao'] = 9
      } else if (type === 'PORTIFOLIO') {
        url = `${influencerData.link_portifolio}`
        params['tipo_notificacao'] = 10
      }
      
      window.open(url, '_blank', 'noopener,noreferrer')

      if (clientId) {
        params['cliente'] = clientId
        await api.post("/api/v1/eventos/instagram/",
          params,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          })
      } else {
        await api.post("/api/v1/eventos/instagram/", params);
      }
    } catch (error) {
      toast.error(`${error.message}`);
    }
  }

  const openTikTok = () => {
    const url = `https://www.tiktok.com/@${tiktokInfluencer}`;
    window.open(url, '_blank');
  }

  const openWhatsApp = () => {
    const url = `https://wa.me/${onlyNumber(influencerData.whatsapp)}?text=Ol%C3%A1!%20Vi%20seu%20portf%C3%B3lio%20na%20plataforma%20Boraver%20e%20gostaria%20de%20saber%20mais%20sobre%20como%20voc%C3%AA%20trabalha.%20Pode%20me%20explicar%3F`

    window.open(url, "_blank")
  }

  if (influencerData) {
    return (
      <div>
        {shouldOpenModal && (
          <ServiceModal
            setShouldOpenModal={setShouldOpenModal}
            shouldOpenModal={shouldOpenModal}
            title={"Solicitar Creator"}
            setShowNewService={setShowNewService}
            setSegmentos={setSegmentos}
          >
            <div className="contentModal">
              <div>
                <h3 style={{ marginBottom: 4 }}>Escolha o serviço mais adequado para sua empresa</h3>
                <p style={{ marginBottom: 6 }}>Escolha uma ou mais opções</p>
                <PacotesList>
                  {pacotes.map((item) => (
                    <ItemPacote
                      choice={isChoiced(item.id) > -1}
                      key={item.id}
                      onClick={() => handleChoice(item)}
                    >
                      <p>{item.nome}</p>
                      <span>{toReal(Number(item.valor))}</span>
                    </ItemPacote>
                  ))}
                </PacotesList>
                {choicePacotes.length > 0 ? (
                  <>
                    <h3>Detalhes</h3>
                    <p>{choicePacotes[0].descricao}</p>
                  </>
                ) : null}

                <p style={{ color: "var(--purple)", marginTop: 20 }}>
                  Não encontrou o pacote que você deseja?
                  <TextButton onClick={() => setShowNewService(true)}>
                    <strong>Clique aqui</strong>
                  </TextButton>
                </p>

                {showNewService ? (
                  <RegisterNewService
                    valorServico={valorServico}
                    servico={servico}
                    descricaoServico={descricaoServico}
                    setServico={setServico}
                    setValorServico={setValorServico}
                    setDescricaoServico={setDescricaoServico}
                    setPacotes={setPacotes}
                    setShowNewService={setShowNewService}
                    pacotes={pacotes}
                  />
                ) : null}

                <Total style={{ marginBottom: 6 }}>
                  Valor total:{" "}
                  {exclusividade
                    ? toReal(calculaPorcentagem(sumChoice()))
                    : toReal(sumChoice())}
                </Total>
                {/* {exclusividade ? (
                  <Total>
                    Valor exclusividade: {toReal(sumChoice() * (20 / 100))}
                  </Total>
                ) : null} */}
              </div>

              <div
                className="periodo-container"
                style={{
                  overlayY: "hidden",
                }}
              >
                <h3>Início de divulgação</h3>
                <select
                  name="inicio"
                  id="inicio"
                  // defaultValue="15 dias"
                  onChange={({ target }) => {
                    if (target.value === "Personalizar") {
                      setPersonalizar(true);
                    } else {
                      setPersonalizar(false);
                      handleValue("inicio", target.value);
                    }
                  }}
                >
                  <option value={""}>Selecionar</option>
                  {inicio.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {personalizar ? (
                  <>
                    <p>Digite o período de divulgação</p>
                    <input
                      type="text"
                      onChange={({ target }) =>
                        handleValue("periodo", target.value)
                      }
                    />
                  </>
                ) : null}
              </div>

              <div className="select-segment">
                <h3 style={{ marginBottom: 4 }}>Seguimento</h3>
                <p>Escolha o seu seguimento</p>

                <div>
                  <select
                    name="segmento"
                    id="segmento"
                    onChange={({ target }) => {
                      handleIdSegment(target.value);
                    }}
                  >
                    <option value={0}>Não Selecionado</option>
                    {segmentos.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </select>
                  {/* <label htmlFor="exclusividade">
                    <div className="exclusividade-content">
                      <span>Exclusivo</span>
                      <input
                        checked={exclusividade}
                        onChange={({ target }) => {
                          handleExclusividade(target.value);
                        }}
                        // onChange={handleExclusividade}
                        className="exclusividade-input"
                        type="checkbox"
                        id="exclusividade"
                      />
                    </div>
                  </label> */}
                </div>
              </div>
              {/* <div>
                <h3>Entre em contato</h3>
                <p>Utilize esse espaço para descrever o serviço</p>
                <textarea onChange={({ target }) => handleValue("descricao_servico", target.value)} />
              </div> */}
              <Button
                setBackgroundColor="var(--purple)"
                setColor="var(--white)"
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Encaminhar Proposta
              </Button>
            </div>
          </ServiceModal>
        )}
        {shouldOpenModalVisitor && (
          <ServiceModal
            setShouldOpenModal={setShouldOpenModalVisitor}
            shouldOpenModal={shouldOpenModalVisitor}
            title={"Solicitar Creator"}
            setShowNewService={setShowNewService}
            setSegmentos={setSegmentos}
          >
            <div className="contentModal">
              <div>
                <h3 style={{ marginBottom: 4 }}>Escolha o serviço mais adequado para sua empresa</h3>
                <p style={{ marginBottom: 6 }}>Escolha uma ou mais opções</p>
                <PacotesList>
                  {pacotes.map((item) => (
                    <ItemPacote
                      choice={isChoiced(item.id) > -1}
                      key={item.id}
                      onClick={() => handleChoice(item)}
                    >
                      <p>{item.nome}</p>
                      <span>{toReal(Number(item.valor))}</span>
                    </ItemPacote>
                  ))}
                </PacotesList>
                {choicePacotes.length > 0 ? (
                  <>
                    <h3>Detalhes</h3>
                    <p>{choicePacotes[0].descricao}</p>
                  </>
                ) : null}

                <p style={{ color: "var(--purple)", marginTop: 20 }}>
                  Não encontrou o pacote que você deseja?
                  <TextButton onClick={() => setShowNewService(true)}>
                    <strong>Clique aqui</strong>
                  </TextButton>
                </p>

                {showNewService ? (
                  <RegisterNewService
                    valorServico={valorServico}
                    servico={servico}
                    descricaoServico={descricaoServico}
                    setServico={setServico}
                    setValorServico={setValorServico}
                    setDescricaoServico={setDescricaoServico}
                    setPacotes={setPacotes}
                    setShowNewService={setShowNewService}
                    pacotes={pacotes}
                  />
                ) : null}

                <Total style={{ marginBottom: 6 }}>
                  Valor total:{" "}
                  {exclusividade
                    ? toReal(calculaPorcentagem(sumChoice()))
                    : toReal(sumChoice())}
                </Total>
                {/* {exclusividade ? (
                  <Total>
                    Valor exclusividade: {toReal(sumChoice() * (20 / 100))}
                  </Total>
                ) : null} */}
              </div>

              <div
                className="periodo-container"
                style={{
                  overlayY: "hidden",
                }}
              >
                <h3>Início de divulgação</h3>
                <select
                  name="inicio"
                  id="inicio"
                  // defaultValue="15 dias"
                  onChange={({ target }) => {
                    if (target.value === "Personalizar") {
                      setPersonalizar(true);
                    } else {
                      setPersonalizar(false);
                      handleValue("inicio", target.value);
                    }
                  }}
                >
                  <option value={""}>Selecionar</option>
                  {inicio.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {personalizar ? (
                  <>
                    <p>Digite o período de divulgação</p>
                    <input
                      type="text"
                      onChange={({ target }) =>
                        handleValue("periodo", target.value)
                      }
                    />
                  </>
                ) : null}
              </div>

              <div className="select-segment">
                <h3 style={{ marginBottom: 4 }}>Seguimento</h3>
                <p>Escolha o seu seguimento</p>

                <div>
                  <select
                    name="segmento"
                    id="segmento"
                    onChange={({ target }) => {
                      handleIdSegment(target.value);
                    }}
                  >
                    <option value={0}>Não Selecionado</option>
                    {segmentos.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.nome}
                        </option>
                      );
                    })}
                  </select>
                  {/* <label htmlFor="exclusividade">
                    <div className="exclusividade-content">
                      <span>Exclusivo</span>
                      <input
                        checked={exclusividade}
                        onChange={({ target }) => {
                          handleExclusividade(target.value);
                        }}
                        // onChange={handleExclusividade}
                        className="exclusividade-input"
                        type="checkbox"
                        id="exclusividade"
                      />
                    </div>
                  </label> */}
                </div>
              </div>
              {/* <div>
                <h3>Entre em contato</h3>
                <p>Utilize esse espaço para descrever o serviço</p>
                <textarea onChange={({ target }) => handleValue("descricao_servico", target.value)} />
              </div> */}
              <Button
                setBackgroundColor="var(--purple)"
                setColor="var(--white)"
                type="submit"
                onClick={() => confirmSolicitationVisitor()}
              >
                Encaminhar Proposta
              </Button>
            </div>
          </ServiceModal>
        )}
        {shouldOpenModalConfirm && (
          <ServiceModal
            setShouldOpenModal={setShouldOpenModalConfirm}
            shouldOpenModal={shouldOpenModalConfirm}
            title={"Finalize envio da sua proposta"}
            setShowNewService={setShowNewService}
            setSegmentos={setSegmentos}
            confirmVisitor={true}
          >
            <div className="content-modal">
              <div>
                <h1
                  style={{
                    fontSize: "1.5rem",
                    textAlign: "center",
                    marginInline: "auto",
                    width: "90%",
                  }}
                >
                  Preencha os dados abaixo para concluir a solicitação
                </h1>
              </div>
              <div style={{ marginTop: 16 }}>
                <label htmlFor="nome">Nome da empresa</label>
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  placeholder="Digite o nome da empresa"
                  value={payload.nome_empresa}
                  onChange={({ target }) =>
                    handlePayloadVisitor("nome_empresa", target.value)
                  }
                  style={{ marginBottom: 10 }}
                />

                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Digite o seu email"
                  value={payload.email_cliente}
                  onChange={({ target }) =>
                    handlePayloadVisitor("email_cliente", target.value)
                  }
                  style={{ marginBottom: 10 }}
                />

                <label htmlFor="fone">Contato</label>
                <InputMask
                  mask={"(99) 99999-9999"}
                  name="fone"
                  id="fone"
                  value={payload.celular}
                  onChange={({ target }) =>
                    handlePayloadVisitor("celular_cliente", target.value)
                  }
                />

                <div className="inputPlace">
                  <select
                    name="state"
                    id="state"
                    value={stateSelected ?? ""}
                    onChange={({ target }) => filterState(target.value)}
                  >
                    <option value="" disabled selected>
                      Selecione Estado
                    </option>
                    {uf.map((state) => (
                      <option value={state.nome}>{state.nome}</option>
                    ))}
                  </select>
                  <select
                    name="city"
                    id="city"
                    value={citySelected ?? ""}
                    onChange={({ target }) => {
                      handleCitySelected(target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Selecione Cidade
                    </option>
                    {city.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>

                <Button
                  setBackgroundColor="var(--purple)"
                  setColor="var(--white)"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  style={{ marginTop: 20 }}
                >
                  Enviar Agora
                </Button>
              </div>
            </div>
          </ServiceModal>
        )}
        {/* <Header isAuth={auth} /> */}
        <Container logged={auth ? true : false}>
          {/* {images.length ? (
            <Content>
              <DivColumn>
                <ImageStorie src={images[0].profile_picture ? images[0].profile_picture : AliciaPic} alt="Foto Storie" />
              </DivColumn>
              <DivColumn>
                <ImageFeed src={images[1].profile_picture ? images[1].profile_picture : AliciaPic} alt="Foto Feed" />
                <ImageFeed className="lastItem" src={images[2].profile_picture ? images[2].profile_picture : AliciaPic} alt="Foto Feed" style={{ marginTop: 30 }} />
              </DivColumn>
              <DivColumn>
                <ImageStorie src={images[3].profile_picture ? images[3].profile_picture : AliciaPic} alt="Foto Storie" />
              </DivColumn>
            </Content>
          ) : null} */}
          <Content>
            <DivColumn style={{ width: "90%" }}>
              <Highlights>
                <div className="inFluencerPicture">
                  <img
                    src={
                      influencerData && influencerData.profile_picture
                        ? influencerData.profile_picture
                        : AliciaPic
                    }
                    alt="Imagem do influenciador"
                    loading="eager"
                  />
                </div>
                <div className="influencerName">
                  <h2>{influencerData.nome ? influencerData.nome : ""}</h2>
                </div>
                <div className="cidade">
                  <p>
                    {influencerData.cidade}
                    {influencerData.estado
                      ? ` - ${influencerData.estado}`
                      : null}
                  </p>
                </div>
                <div className="sociais">
                  {tiktokInfluencer ? (
                    <button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => checkoutInstagram('TIKTOK')}
                    >
                      <FaTiktok />
                    </button>
                  ) : null}
                  {instaInfluencer ? (
                    <button
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => checkoutInstagram('INSTAGRAM')}
                    >
                      <FaInstagram />
                    </button>
                  ) : null}
                </div>
                <div className="portifolio">
                  {influencerData.link_portifolio ? (
                    <Button setBackgroundColor="var(--white)" setColor="#2F251B" onClick={() => checkoutInstagram('PORTIFOLIO')}>
                      {/* <a
                        href={influencerData.link_portifolio}
                        target="_blank"
                        rel="noopener noreferrer"
                      > */}
                        Ver Portfólio
                      {/* </a> */}
                    </Button>
                  ) : null}
                </div>
              </Highlights>
              <Details>
                <Informations>
                  <div className="sobre">
                    <h3>Sobre o {influencerData.nome}</h3>
                    {influencerData.sobre ? (
                      <p>{influencerData?.sobre}</p>
                    ) : (
                      <p>
                        Sou um creator UGC apaixonado por produzir material autêntico e envolvente. Com experiência em diversas plataformas digitais, crio posts e vídeos que capturam a essência das marcas. Minha abordagem é inovadora e focada em conectar-se genuinamente com o público.
                      </p>
                    )}
                  </div>
                  {pacotes.length ? (
                  <div className="pacotes">
                    <h3>Pacotes oferecidos pelo {influencerData.nome}</h3>
                    <div className="row">
                      {pacotes.map((pacote) => (
                        <div className="card" key={pacote.id}>
                          <div className="row">
                            <h3>{pacote.nome}</h3>
                            <span>{pacote.valor ? toReal(+pacote.valor) : "R$ 0,00"}</span>
                          </div>
                          <div className="row">
                            <p>{pacote.descricao}</p>
                          </div>
                          <div className="checkoutButton">
                            <button onClick={() => openWhatsApp()}>Iniciar Conversa</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  ) : null}
                  {/* <div>
                    <h3>Cidade / Estado</h3>
                    <p>
                      {influencerData.cidade}
                      {influencerData.estado
                        ? ` - ${influencerData.estado}`
                        : null}
                    </p>
                    <p>
                      Seguimentos:{" "}
                      {influencerData.segmentos_order
                        ? influencerData.segmentos_order
                          .map((item) => `${item.segmento}`)
                          .join(", ")
                        : null}
                    </p>
                  </div> */}
                  {/* <div>
                    <h3>Redes sociais</h3>
                    {influencerData.qtd_intagram ? (
                      <p>
                        Instagram: {formatFollowers(influencerData.qtd_intagram)}{" "}
                        seguidores
                      </p>
                    ) : null}
                    {influencerData.qtd_tiktok ? (
                      <p>
                        TikTok: {formatFollowers(influencerData.qtd_tiktok)}{" "}
                        seguidores
                      </p>
                    ) : null}
                    {influencerData.qtd_youtube ? (
                      <p>
                        YouTube: {formatFollowers(influencerData.qtd_youtube)}{" "}
                        inscritos
                      </p>
                    ) : null}
                  </div> */}
                  {/* <div>
                    <h3>Principal Rede Social</h3>
                    {!!influencerData.redesocial_principal &&
                      influencerData.redesocial_principal}
                  </div> */}
                  {/* <div>
                    <h3>Sobre</h3>
                    {influencerData.sobre ? (
                      <p>{influencerData?.sobre}</p>
                    ) : (
                      <p>
                        Sou um creator UGC apaixonado por produzir material autêntico e envolvente. Com experiência em diversas plataformas digitais, crio posts e vídeos que capturam a essência das marcas. Minha abordagem é inovadora e focada em conectar-se genuinamente com o público.
                      </p>
                    )}
                  </div> */}
                </Informations>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* <Button
                    setBackgroundColor="var(--purple)"
                    setColor="var(--white)"
                    onClick={() => handleSolicitation()}
                  // large
                  >
                    Solicitar Creator
                  </Button> */}

                  {/* {auth ? (
                    <button
                      onClick={() => acenar()}
                      style={{
                        cursor: acenado ? "not-allowed" : "pointer",
                        marginBottom: 10,
                        padding: "8px 23.5px"
                      }}
                    >
                      <strong>{acenado ? "Acenou" : "Acenar"}</strong>
                      <img src={Hand} alt="ícone de uma mão acenando" />
                    </button>
                  ) : null} */}
                </div>
              </Details>
            </DivColumn>
          </Content>
        </Container>
        {/* <Footer /> */}
      </div>
    );
  } else {
    return <div></div>;
  }
};
export default InfluencerDetails;
