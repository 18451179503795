import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Content = styled.div`
  width: 90%;
  padding: 2rem 0;
  margin-bottom: 2rem;
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-self: center;

  h2 {
    color: var(--purple);
    border-bottom: 2px solid var(--gray);
    padding: 10px 0px;
    margin: 1rem 0;
  }
  .paymentType {
    input {
      margin-right: 10px;
    }
  }
  .inputBox {
    padding: 10px 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 24px;
      margin-right: 10px;
    }
    button {
      border: none;
      background-color: transparent;
      font-size: 18px;
      margin-right: 10px;
      font-weight: 600;
      cursor: pointer;
      :hover {
        color: var(--brightpurple);
      }
    }
  }
`;

export const AboutProposition = styled.div`
  p {
    margin: 20px 20px;
  }
  span {
    font-weight: 600;
    color: var(--black);
  }
  h4 {
    margin: 20px 20px;
    color: var(--purple);
    font-size: 20px;
  }
  .btnPlace {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
`;

export const RightSide = styled.div`
  max-width: 40%;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
