import { createContext, useContext, useState, useEffect } from "react";
import api from "../../services";
import { useAuth } from "../auth";

const UserContext = createContext();

export const UserProvider = ({children} ) => {
  const { auth } = useAuth()

  const [userId, setUserId] = useState()
  const [clientId, setClientId] = useState()
  const [userData, setUserData] = useState()
  const [userNotifications, setUserNotifications] = useState()
  const [unreadNotifications, setUnreadNotifications] = useState()
  
  useEffect(() => {
    if(auth) {
      const userLoged = localStorage.getItem("userBoraVer");
      if(userLoged){
        let user = JSON.parse(userLoged)
        setUserId(user.user.id)
      }
    }
  }, [auth])

  const registerUserData = async () => {

    if(userId){
      const response = await api
        .get(`/api/v3/clienteusuario/${userId}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            },
        })
        .then((response) => {
          setUserData(response.data.results[0])
          setClientId(response.data.results[0].id);
        })
  
        return response
    }

  }

  const getClientId = async () => {

    if(userId){
      const response = await api
        .get(`/api/v3/clienteusuario/${userId}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            },
        })
        .then((response) => {
          setClientId(response.data.results[0].id);
        })
  
        return response
    }
    
  }

  const getNotifications = async () => {

    if(userId){
      const response = await api
        .get(`/api/v3/listanotificacaomes_influencer/${userId}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            },
        })
        .then((response) => {
          setUserNotifications(response.data.results);
        })
  
        return response
    }
    
  }

  const getUnreadNotifications = async () => {

    if(userId){
      const response = await api
        .get(`/api/v3/listanotificacao_cliente/${userId}/`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`,
            },
        })
        .then((response) => {
          setUnreadNotifications(response.data.results);
        })
  
        return response
    }
    
  }

  useEffect(()=>{
    registerUserData()
    // getClientId()
    getNotifications()
    getUnreadNotifications()
  }, [userId, setUserId])

  return (
    <UserContext.Provider value={{ 
      userData, 
      setUserData, 
      userId, 
      setUserId, 
      userNotifications, 
      setUserNotifications, 
      unreadNotifications, 
      setUnreadNotifications, 
      clientId, 
      setClientId 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useClient = () => useContext(UserContext);