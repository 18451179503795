import React, { useCallback, useEffect, useState } from "react";
import { DivRow, Card, DivColumn, Content } from "./styles";
import { Container, Loading } from "../Solicitations/styles";
import {
  DeletePackage,
  EditPackage,
  PackageClient,
} from "../../services/cliente/client";
import { useAuth } from "../../providers/auth";
import { useClient } from "../../providers/user";
import Modal from "../../components/Modal/Modal";
import { toast } from "react-toastify";
import { Button } from "../ClientProfile/styles";
import ImgUser from "../../assets/user.webp";
import { DivBetween, DivImage, Title } from "../VitrineCreators/styles";
import InputSearch from "../../components/InputSearch";
import { formatDate } from "../../utils/helpers";

const MyPackages = () => {
  const { auth } = useAuth();
  const { userData, setUserData, clientId } = useClient();
  const [packages, setPackages] = useState([]);
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [loadingDeletePackage, setLoadingDeletePackage] = useState(false);
  const [loadingEditPackage, setLoadingEditPackage] = useState(false);
  const [titleModalPackage, setTitleModalPackage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [modalVisibleDeletePackage, setModalVisibleDeletePackage] =
    useState(false);
  const [modalVisibleEditPackage, setModalVisibleEditPachage] = useState(false);
  const [payload, setPayload] = useState({
    cliente: clientId,
    nome: "",
    valor: 0,
    descricao: "",
  });

  useEffect(() => {
    if (clientId) loadPacotes();
  }, []);

  const loadPacotes = useCallback(async () => {
    try {
      setLoadingPackages(true);
      const result = await PackageClient(clientId, auth);

      if (result) {
        setPackages(result);
        setLoadingPackages(false);
      }
    } catch (err) {
      setLoadingPackages(false);
      console.error("Erro: ", err);
    }
  }, []);

  const activeModalPackage = (title, pacote) => {
    if (pacote) {
      setPayload({
        ...payload,
        id: pacote.id,
        nome: pacote.nome,
        valor: pacote.valor,
        descricao: pacote.descricao,
      });
    } else {
      const newPayload = {
        cliente: clientId,
        nome: "",
        valor: 0,
        descricao: "",
      };
      setPayload(newPayload);
    }
    setTitleModalPackage(title);
    if (title === "Apagar Campanha") {
      setModalVisibleDeletePackage(true);
    } else {
      setModalVisibleEditPachage(true);
    }
  };

  const submitPackage = async () => {
    if (validate()) {
      setLoadingEditPackage(true);
      try {
        if (payload.id) {
          let _payload = {
            nome: payload.nome,
            valor: payload.valor,
            descricao: payload.descricao,
          };

          const result = await EditPackage(payload.id, _payload, auth);
          if (result) {
            toast.success("Campanha editada com sucesso!");
            setLoadingEditPackage(false);
            setModalVisibleEditPachage(false);
            let _package = packages.map((pacote) => {
              if (pacote.id === result.id) {
                return {
                  ...pacote,
                  nome: result.nome,
                  valor: result.valor,
                  descricao: result.descricao,
                };
              } else {
                return pacote;
              }
            });
            setPackages(_package);
          }
        }
      } catch (error) {
        setLoadingEditPackage(false);
        setModalVisibleEditPachage(false);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!payload.nome) {
      toast.error("Digite o nome do pacote");
      return false;
    }
    if (!payload.valor) {
      toast.error("Digite o valor do pacote");
      return false;
    }
    if (!payload.descricao) {
      toast.error("Digite a descrição do pacote");
      return false;
    }
    return true;
  };

  const deletePackage = async () => {
    if (payload.id) {
      try {
        setLoadingDeletePackage(true);
        const result = await DeletePackage(payload.id, auth);
        if (result) {
          let _package = packages.filter((pacote) => pacote.id !== payload.id);

          setPackages(_package);
          toast.success("Campanha excluída");
          setLoadingDeletePackage(false);
          setModalVisibleDeletePackage(false);
        }
      } catch (error) {
        setLoadingDeletePackage(false);
        console.error("Erro: ", error);
      }
    }
  };

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  return (
    <Content style={{ justifyContent: "center" }}>
      <DivColumn>
        <DivBetween>
          <Title>Minhas Campanhas</Title>
          <DivImage>
            <img
              src={
                userData && userData.profile_picture
                  ? userData.profile_picture
                  : ImgUser
              }
              alt="Imagem do usuário"
              height={60}
              width={60}
            />
          </DivImage>
        </DivBetween>
        <InputSearch
          bgColor={"var(--white)"}
          name="search"
          value={searchValue}
          placeholder="Pesquisar nome da campanha"
          handleChange={handleSearchChange}
          submit={loadPacotes}
        />
        <DivRow>
          {packages.map((pacote) => (
            <Card key={pacote.id}>
              <h2>{pacote.nome} @boraverapp</h2>
              <p>Publicada: {pacote.criado_em ? formatDate(pacote.criado_em) : ''}</p>
              <p>
                <span>Valor:</span> R$ {pacote.valor}
              </p>
              <p>
                <span>Descrição:</span> {pacote.descricao}
              </p>
              <DivRow className="justify-end">
                <button
                  className="primary"
                  onClick={() => activeModalPackage("Editar Campanha", pacote)}
                >
                  Editar
                </button>
                <button
                  className="danger"
                  onClick={() => activeModalPackage("Apagar Campanha", pacote)}
                >
                  Excluir
                </button>
              </DivRow>
            </Card>
          ))}
        </DivRow>
      </DivColumn>

      {/* Editar Campanha */}
      {modalVisibleEditPackage ? (
        <Modal
          title={titleModalPackage}
          setShouldOpenModal={() => setModalVisibleEditPachage(false)}
        >
          <div>
            <div>
              <Container style={{ flexDirection: "column", marginTop: 10 }}>
                <label htmlFor="nome">Nome da oferta</label>
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  value={payload.nome ? payload.nome : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) => handlePayload("nome", target.value)}
                />
              </Container>
              <Container style={{ flexDirection: "column" }}>
                <label htmlFor="valorServico">Valor</label>
                <input
                  type="number"
                  name="valorServico"
                  id="valorServico"
                  value={payload.valor ? payload.valor : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("valor", target.value)
                  }
                />
              </Container>
              <Container style={{ flexDirection: "column" }}>
                <label htmlFor="descricaoServico">Descrição</label>
                <textarea
                  id="descricaoServico"
                  value={payload.descricao ? payload.descricao : ""}
                  style={{ width: "100%" }}
                  onChange={({ target }) =>
                    handlePayload("descricao", target.value)
                  }
                />
              </Container>
              <DivRow style={{ justifyContent: "flex-end" }}>
                {loadingEditPackage ? <Loading style={{ margin: 10 }} /> : null}
                <Button
                  type="button"
                  disabled={loadingEditPackage}
                  onClick={() => submitPackage()}
                >
                  Editar Campanha
                </Button>
              </DivRow>
            </div>
          </div>
        </Modal>
      ) : null}

      {/* Apagar Campanha  */}
      {modalVisibleDeletePackage ? (
        <Modal
          title={titleModalPackage}
          setShouldOpenModal={() => setModalVisibleDeletePackage(false)}
        >
          <Container
            style={{
              height: 200,
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div>
              <h2>Tem certeza que deseja excluir esse pacote?</h2>
              <p
                style={{
                  color: "#000",
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                Esta ação é irreversível
              </p>
            </div>
            <DivRow className="justify-center">
              {loadingDeletePackage ? <Loading style={{ margin: 10 }} /> : null}
              <button
                className="primary"
                onClick={() => setModalVisibleDeletePackage(false)}
                disabled={loadingDeletePackage}
              >
                Cancelar
              </button>
              <button
                className="danger"
                onClick={() => deletePackage()}
                disabled={loadingDeletePackage}
              >
                Apagar
              </button>
            </DivRow>
          </Container>
        </Modal>
      ) : null}
    </Content>
  );
};
export default MyPackages;
